import React, { useState, useEffect } from 'react'
import {
  CopyOutlined,
  DownloadOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { Page, Text, View, Image, Document, StyleSheet, Link } from '@react-pdf/renderer'
import { BlobProvider } from '@react-pdf/renderer'
import FacebookIcon from '../../assets/svg/FacebookIcon'
import YouTubeIcon from '../../assets/svg/YouTubeIcon'
import Instagram from '../../assets/svg/Instagram'
import LinkedIn from '../../assets/svg/LinkedIn'
import Tiktok from '../../assets/svg/Tiktok'
import './style.scss'
import { Input, Button, Modal, Checkbox, message, Upload, Flex } from 'antd'
import moment from 'moment'
import { FacebookShareButton, LinkedinShareButton } from 'react-share'
import { useParams } from 'react-router'
import dayjs from 'dayjs'

const PerformingNoteSharingModal = ({ open, setOpen, scenarios, property, numberFormator }) => {
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [reportId, setReportId] = useState(false)
  const condition = false
  const [logoBase64, setLogoBase64] = useState(null)
  const [fileSize, setFileSize] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [enableSharing, setEnableSharing] = useState(false)

  const handleBeforeUpload = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase()
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg']

    if (!allowedExtensions.includes(fileExtension)) {
      message.error('You can only upload JPG/PNG/SVG files!')
      return false
    }
    if (file && file.size) {
      if (file.size / 1024 / 1024 < 1) {
        setFileSize(true)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          setLogoBase64(reader.result)
        }
        return true // Allow upload
      } else {
        message.error('Logo must be smaller than 1MB!')
      }
    }
    return false // Prevent upload
  }

  const handleChange = (info) => {
    const file = info.file
    if (file.status === 'done') {
      setLogoBase64(file.response)
    }
  }

  const onChange = () => {
    setIsCopy(false)
    setEnableSharing((preVal) => !preVal)
  }

  const handleOk = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setOpen(false)
    }, 3000)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  // Create styles for PDF document
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: 'transparent', // Make the page background transparent to see the gradient
    },
    backgroundImage: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    main: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      padding: 40,
      fontSize: 12,
      flexDirection: 'column',
      justifyContent: 'center',
      border: '1px solid black',
    },
    mainHeading: {
      fontSize: 18,
      fontWeight: 'bold',
    },

    heading: {
      fontSize: 14,
      fontWeight: 'bold',
    },

    text: {
      fontSize: 12,
      color: '#475467',
    },
    logoBar: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    defaultLogo: {
      display: 'flex',
      flexDirection: 'row',
    },
    logo: {
      width: 40,
      height: 30,
      border: '1px solid black',
    },
    infoViewHead: {
      borderBottom: '1px solid black',
    },
    infoView: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'between',
      alignItems: 'center',
      width: '100%',
    },
    infoTextHead: {
      width: '50px',
    },
    informationLine: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: 4,
    },
    propertyInformation: {
      display: 'flex',
      flexDirection: 'row',
      width: '46%',
      // justifyContent: 'space-between',
      color: '#475467',
    },
    informationHeading: {
      textAlign: 'right',
      width: '80px',
    },
    info: {
      paddingLeft: '6px',
    },
    info1: {
      paddingLeft: '9px',
    },
    tableLine: {
      flexDirection: 'row',
      width: '100%',
      borderBottomWidth: '1px',
      borderBottomColor: '#475467',
    },
    tableCell: {
      paddingVertical: 1,
      display: 'flex',
      width: '23%',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: '1px',
      borderRightColor: '#475467',
      color: '#475467',
    },
    tableFistCell: {
      paddingVertical: 1,
      display: 'flex',
      width: '31%',
      borderRight: '1px',
      color: '#475467',
      borderRightColor: '#475467',
      color: '#475467',
    },
    tableCell1: {
      paddingVertical: 1,
      display: 'flex',
      width: '33%',
      borderRight: '1px',
      borderRightColor: '#475467',
      color: '#475467',
    },
    tableFistCell1: {
      paddingVertical: 1,
      display: 'flex',
      width: '34%',
      borderRight: '1px',
      borderRightColor: '#475467',
      color: '#475467',
    },
    tableCell2: {
      paddingVertical: 1,
      display: 'flex',
      width: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: '1px',
      borderRightColor: '#475467',
    },
    tableFistCell2: {
      paddingVertical: 1,
      display: 'flex',
      width: '50%',
      borderRight: '1px',
      borderRightColor: '#475467',
      color: '#475467',
    },
    cellText: {
      textAlign: 'center',
      color: '#475467',
    },
    name: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    address: {
      flexDirection: 'column',
      marginTop: 15,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: 2,
      marginTop: 20,
      marginBottom: 20,
    },
    col: {
      display: 'flex',
      flexDirection: 'row',
    },

    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 6,
      marginLeft: 12,
    },
    footer: {
      marginTop: 'auto',
      borderTop: '1px solid black',
      paddingTop: 5,
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 5,
    },
    footertxt: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    footerlnk: {
      fontSize: 12,
      fontWeight: 'bold',
    },
  })

  const MyDocument = ({ property, scenarios, logoBase64, numberFormator }) => (
    <Document>
      <Page size='A4' style={styles.page}>
        <Image src='/pdfBg.jpg' style={styles.backgroundImage} />
        <View style={styles.main}>
          <View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {/* heder bar */}
              <View style={[styles.logoBar, { alignItems: 'baseline', width: '30%' }]}>
                {logoBase64 ? (
                  <Image src={logoBase64} style={styles.logo} />
                ) : (
                  <View style={styles.defaultLogo}>
                    <Image src='/pdfLogo2.jpg' style={styles.logo} />
                    {/* <Text style={{ fontSize: 16, fontWeight: 'bold' }}>Blu Hat Bookkeeping</Text> */}
                  </View>
                )}
              </View>
              {/* title bar */}
              <View
                style={[
                  styles.logoBar,
                  styles.mainHeading,
                  { justifyContent: 'center', paddingTop: 30, width: '40%' },
                ]}
              >
                <Text style={{}}>Performing Notes</Text>
              </View>
              <View
                style={{
                  width: '30%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              ></View>
            </View>
            {/* property address */}
            <View
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                marginTop: 16,
              }}
            >
              <Text style={styles.heading}>Property’s Address</Text>
              <View style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                <Text style={[styles.text, { minWidth: '90px' }]}>Street Address:</Text>
                <Text style={styles.text}>{property?.address ?? ''}</Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 4,
                  marginBottom: 16,
                  width: '100%',
                }}
              >
                <View style={[styles.text, { display: 'flex', flexDirection: 'row' }]}>
                  <Text style={[styles.text, { minWidth: '30px' }]}>City:</Text>
                  <Text>{property?.city ?? ''} </Text>
                </View>
                <View
                  style={[
                    styles.text,
                    { display: 'flex', flexDirection: 'row', marginLeft: 40, marginRight: 40 },
                  ]}
                >
                  <Text style={[styles.text, { minWidth: '40px' }]}>State:</Text>
                  <Text>{property?.state ?? ''} </Text>
                </View>
                <View style={[styles.text, { display: 'flex', flexDirection: 'row' }]}>
                  <Text style={[styles.text, { minWidth: '30px' }]}>Zip:</Text>
                  <Text>{property?.zipcode ?? ''}</Text>
                </View>
              </View>
            </View>
            {/* property information */}
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={styles.heading}>Property Information</Text>
              <View>
                <View style={styles.informationLine}>
                  <View style={styles.propertyInformation}>
                    <Text style={styles.informationHeading}>(N) Months Left:</Text>
                    {property?.monthsLeft?.toString() ? (
                      <Text style={styles.info}> {property?.monthsLeft}</Text>
                    ) : (
                      <Text style={styles.info}>N/A</Text>
                    )}
                  </View>
                  <View style={styles.propertyInformation}>
                    <Text style={[{ paddingRight: 4 }, styles.informationHeading]}>
                      (UPB) Loan Amount:
                    </Text>
                    {property?.upb ? (
                      <Text style={styles.info}> ${numberFormator(property.upb.toFixed(2))}</Text>
                    ) : (
                      <Text style={styles.info}>N/A</Text>
                    )}
                  </View>
                </View>
                <View style={styles.informationLine}>
                  <View style={styles.propertyInformation}>
                    <Text style={styles.informationHeading}>I/YR:</Text>
                    {property?.ir ? (
                      <Text style={styles.info}> ${numberFormator(property.ir.toFixed(2))}</Text>
                    ) : (
                      <Text style={styles.info}>N/A</Text>
                    )}
                  </View>
                  <View style={styles.propertyInformation}>
                    <Text style={styles.informationHeading}>PMT (Monthly Payment):</Text>
                    {property?.pmt ? (
                      <Text style={styles.info}> ${numberFormator(property.pmt.toFixed(2))}</Text>
                    ) : (
                      <Text style={styles.info}>N/A</Text>
                    )}
                  </View>
                </View>
                <View style={styles.informationLine}>
                  <View style={styles.propertyInformation}>
                    <Text style={styles.informationHeading}>Due Diligence Cost:</Text>
                    {property?.dueDiligenceCost ? (
                      <Text style={styles.info}>
                        {' '}
                        ${numberFormator(property.dueDiligenceCost.toFixed(2))}
                      </Text>
                    ) : (
                      <Text style={styles.info}>$0.00</Text>
                    )}
                  </View>
                  <View style={styles.propertyInformation}>
                    <Text style={styles.informationHeading}>Monthly Servicing Cost:</Text>
                    {property?.monthlyServicingCost ? (
                      <Text style={styles.info}>
                        {' '}
                        ${numberFormator(property.monthlyServicingCost.toFixed(2))}
                      </Text>
                    ) : (
                      <Text style={styles.info}>$0.00</Text>
                    )}
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                marginTop: '30px',
              }}
            >
              {scenarios.map((scenario, index) => {
                if(!scenario?.id){
                  return;
                }
                return (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      marginTop: '30px',
                    }}
                  >
                    {/* <View style={{border:"1px solid black",borderBottom:"1px solid black",width:"100px"}}>
                      <Text>{index+1}</Text>
                    </View> */}
                    <View
                      style={{
                        border: '1px solid black',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        flexWrap: 'wrap',
                      }}
                    >
                      <View style={{ width: '33%', borderRight: '1px dashed black' }}>
                        <Text style={[styles.infoViewHead, { padding: '3px 15px' }]}>% of UPB</Text>
                        <View style={[styles.infoView, { padding: '3px 15px' }]}>
                          <Text style={styles.infoTextHead}>UPB:</Text>
                          {scenario?.upb_1 ? (
                            <Text>{numberFormator(scenario.upb_1.toFixed(2))} %</Text>
                          ) : (
                            <Text>0.00 %</Text>
                          )}
                        </View>
                        <View style={[styles.infoView, { padding: '3px 15px' }]}>
                          <Text style={styles.infoTextHead}>Yield:</Text>
                          {scenario?.yield_1 ? (
                            <Text>{numberFormator(scenario.yield_1.toFixed(2))} %</Text>
                          ) : (
                            <Text>0.00 %</Text>
                          )}
                        </View>
                        <View style={[styles.infoView, { padding: '3px 15px' }]}>
                          <Text style={styles.infoTextHead}>Price:</Text>
                          {scenario?.price_1 ? (
                            <Text>$ {numberFormator(scenario.price_1.toFixed(2))}</Text>
                          ) : (
                            <Text>$ 0.00</Text>
                          )}
                        </View>
                      </View>
                      <View style={{ width: '34%', borderRight: '1px dashed black' }}>
                        <Text style={[styles.infoViewHead, { padding: '3px 15px' }]}>
                          % of Yield
                        </Text>
                        <View style={[styles.infoView, { padding: '3px 15px' }]}>
                          <Text style={styles.infoTextHead}>UPB:</Text>
                          {scenario?.upb_2 ? (
                            <Text>{numberFormator(scenario.upb_2.toFixed(2))} %</Text>
                          ) : (
                            <Text>0.00 %</Text>
                          )}
                        </View>
                        <View style={[styles.infoView, { padding: '3px 15px' }]}>
                          <Text style={styles.infoTextHead}>Yield:</Text>
                          {scenario?.yield_2 ? (
                            <Text>{numberFormator(scenario.yield_2.toFixed(2))} %</Text>
                          ) : (
                            <Text>0.00 %</Text>
                          )}
                        </View>
                        <View style={[styles.infoView, { padding: '3px 15px' }]}>
                          <Text style={styles.infoTextHead}>Price:</Text>
                          {scenario?.price_2 ? (
                            <Text>$ {numberFormator(scenario.price_2.toFixed(2))}</Text>
                          ) : (
                            <Text>$ 0.00</Text>
                          )}
                        </View>
                      </View>
                      <View style={{ width: '33%' }}>
                        <Text style={[styles.infoViewHead, { padding: '3px 15px' }]}>Price</Text>
                        <View style={[styles.infoView, { padding: '3px 15px' }]}>
                          <Text style={styles.infoTextHead}>UPB:</Text>
                          {scenario?.upb_3 ? (
                            <Text>{numberFormator(scenario.upb_3.toFixed(2))} %</Text>
                          ) : (
                            <Text>0.00 %</Text>
                          )}
                        </View>
                        <View style={[styles.infoView, { padding: '3px 15px' }]}>
                          <Text style={styles.infoTextHead}>Yield:</Text>
                          {scenario?.yield_3 ? (
                            <Text>{numberFormator(scenario.yield_3.toFixed(2))} %</Text>
                          ) : (
                            <Text>0.00 %</Text>
                          )}
                        </View>
                        <View style={[styles.infoView, { padding: '3px 15px' }]}>
                          <Text style={styles.infoTextHead}>Price:</Text>
                          {scenario?.price_3 ? (
                            <Text>$ {numberFormator(scenario.price_3.toFixed(2))}</Text>
                          ) : (
                            <Text>$ 0.00</Text>
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                )
              })}
            </View>
            {/* table for three Scenario */}
            {/* //////////////////////////////////////////////// */}
            {/* table for two Scenario */}
            {/* //////////////////////////////////////////////// */}
            {/* table for one Scenario */}
          </View>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footertxt}>
            Create your free bookkeeping scenario at{' '}
            <Link src='https://bluhatbookkepping.com/' style={styles.footerlnk}>
              BluHatBookKeeping.com
            </Link>{' '}
            or book a free call to simplify your finances now!
          </Text>
        </View>
      </Page>
    </Document>
  )

  const openInNewTab = (dataUri) => {
    const newWindow = window.open()
    newWindow.document.write('<iframe width="100%" height="100%" src="' + dataUri + '"></iframe>')
  }
  useEffect(() => {
    if (params?.id) {
      setReportId(params.id)
    }
  }, [params])
  return (
    <div>
      <Modal
        visible={open}
        title='Sharing & PDF Settings'
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <p>
          User Name <span style={{ color: '#7F56D9' }}>sksk@demo.com</span>
        </p>
        <p>Last updated {dayjs(property.updatedAt).format('YYYY-MM-DD')}</p>

        <div className='font-medium text-sm mt-4'>
          <Checkbox onChange={onChange}>Enable report sharing</Checkbox>
        </div>

        <div>
          <Checkbox onChange={onChange}>
            <p className='font-medium text-sm'>Show property address when sharing</p>
          </Checkbox>
          <p className='ml-6'>You can enable or disable sharing of this report at any time.</p>
        </div>

        <div className='mt-3 ml-6'>
          <p className='italic'>
            By enabling report sharing, you acknowledge and agree that your report will be made
            available to any parties to whom you provide access.
          </p>
        </div>

        <div className='font-medium text-sm mt-4'>
          <p className='mb-2'>Link to this report</p>
          <Input
            value={
              enableSharing
                ? `${window.location.protocol}//${window.location.host}/performingNote/${reportId}`
                : ''
            }
            addonAfter={
              isCopy && enableSharing ? (
                <span className='-mx-1'>✔️</span>
              ) : (
                <CopyOutlined
                  onClick={() => {
                    setIsCopy(true)
                    navigator.clipboard.writeText(
                      `${window.location.protocol}//${window.location.host}/flipProperty/${reportId}`,
                    )
                  }}
                />
              )
            }
          />
        </div>

        <div className='mt-4' style={{ width: '100%' }}>
          <p className='mb-2 font-medium text-sm'>Add a logo to your PDF report</p>
          <Upload
            className='full-width-upload'
            // action='/your-upload-url'
            beforeUpload={handleBeforeUpload}
            onChange={handleChange}
            showUploadList={false}
            accept='.jpg,.png,.svg'
          >
            {logoBase64 ? null : (
              <Button
                type='dashed'
                block
                className='flex items-center justify-between h-12'
                style={{ borderColor: '#7F56D9' }}
              >
                <span style={{ color: '#7F56D9' }}>+ Upload Logo</span>
                <CloudDownloadOutlined style={{ color: '#7F56D9' }} />
              </Button>
            )}
          </Upload>
          {logoBase64 ? (
            <div className='flex w-full justify-between border rounded-md h-12'>
              <img src={logoBase64} className='w-18 h-full rounded-l-md' />
              <button
                onClick={() => {
                  setLogoBase64(null)
                }}
              >
                <DeleteOutlined className='flex justify-center items-center w-9 h-full border-l' />
              </button>
            </div>
          ) : (
            ''
          )}
          <p>You can only upload a jpg, jpeg, png, or gif.</p>
        </div>

        <div className=' mt-4'>
          <p className='mb-2 font-medium text-sm'>PDF Report Setting</p>
          <BlobProvider
            document={
              <MyDocument
                scenarios={scenarios}
                property={property}
                logoBase64={logoBase64}
                numberFormator={numberFormator}
              />
            }
          >
            {({ blob, url }) => {
              const handleClick = () => {
                if (!url) return
                const ele = document.createElement('a')
                ele.href = url
                ele.download = 'document.pdf'
                ele.click()
              }
              return (
                <button
                  className={`flex w-full items-center text-center justify-center border rounded-lg py-2 font-bold ${!url ? 'bg-[white] text-[black]' : 'bg-purple-600 text-white'}`}
                  icon={<DownloadOutlined />}
                  onClick={handleClick}
                  disabled={!url}
                >
                  Download PDF
                </button>
              )
            }}
          </BlobProvider>
          <p>You can only upload a jpg, jpeg, png, or gif.</p>
        </div>

        <div className='mt-4'>
          <p className='font-medium text-sm'>Share this report on </p>

          <div className='flex flex-row gap-4 mt-2 justify-between'>
            <FacebookShareButton
              url={'https://www.example.com'}
              quote={'Dummy text!'}
              hashtag='#muo'
            >
              <FacebookIcon />
            </FacebookShareButton>
            {/* <Button>
              <FacebookIcon />
            </Button> */}
            <Button>
              <YouTubeIcon/>
            </Button>
            <LinkedinShareButton
              url={'https://www.example.com'}
              quote={'Dummy text!'}
              hashtag='#muo'
            >
              <LinkedIn />
            </LinkedinShareButton>
            {/* <Button>
              <LinkedIn />
            </Button> */}
            <Button>
              <Instagram />
            </Button>
            <Button>
              <Tiktok />
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PerformingNoteSharingModal
