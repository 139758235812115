import './styles.scss'
const BookACall = ({
  title,
  description,
  btnText,
  handleBtnClick,
  bgColor = 'bg-[#F5F5F5]',
  shadowClass = '',
}) => {
  return (
    <div
      className={`${bgColor} ${shadowClass} w-full mx-auto text-center rounded-lg shadow-lg overflow-hidden py-6 sm:p-10`}
    >
      <h2 className='text-xl lg:text-3xl font-medium mb-4 text-gray-800'>{title}</h2>
      <p className='text-gray-600 text-sm lg:text-base mb-6 leading-relaxed'>{description}</p>
      <div className='text-center'>
        <button
          className='bg-black text-white text-sm lg:text-base py-2 px-3 lg:px-6 rounded-md'
          onClick={handleBtnClick}
        >
          {btnText}
        </button>
      </div>
    </div>
  )
}
export default BookACall
