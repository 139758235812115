import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './hooks/useAppContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// Function to generate PDF
// const generatePdf = () => {
//   const doc = new jsPDF('p', 'pt', 'a4', false)

//   const texts = { property: property, scenario: scenarios }
//   console.log('texts', texts)

//   // Adjust the font size for the body
//   // Start Property Address
//   doc.setFontSize(16)
//   doc.text(20, 20, 'Property Address')
//   doc.setFontSize(12)
//   doc.text(30, 40, `Address: ${texts?.property?.address ?? ''}`)
//   doc.text(30, 55, `state: ${texts?.property?.city ?? ''}`)
//   doc.text(30, 70, `City: ${texts?.property?.state ?? ''}`)
//   doc.text(30, 85, `street: ${texts?.property?.zipcode ?? ''}`)

//   const boxWidth = 100
//   const boxHeight = 30
//   const boxMargin = 20
//   const startY = 20

//   doc.setFontSize(12)
//   doc.setFillColor('#FF0000') // Red color
//   doc.rect(boxMargin, startY, boxWidth, boxHeight, 'F') // First box

//   doc.text('Text 1', boxMargin + 10, startY + 15) // Text inside first box

//   doc.setFontSize(12)
//   doc.setFillColor('#00FF00') // Green color
//   doc.rect(boxMargin + boxWidth + boxMargin, startY, boxWidth, boxHeight, 'F')
//   doc.text('Text 2', boxMargin + boxWidth + boxMargin + 10, startY + 15) // Text inside second box

//   doc.setFillColor('#0000FF') // Blue color
//   doc.rect(boxMargin + (boxWidth + boxMargin) * 2, startY, boxWidth, boxHeight, 'F')
//   doc.text('Text 3', boxMargin + (boxWidth + boxMargin) * 2 + 10, startY + 15) // Text inside third box

//   doc.setFillColor('#FFFF00') // Yellow color
//   doc.rect(boxMargin + (boxWidth + boxMargin) * 3, startY, boxWidth, boxHeight, 'F')
//   doc.text('Text 4', boxMargin + (boxWidth + boxMargin) * 3 + 10, startY + 15)

//   // Start Property Photo here
//   doc.setFontSize(16)
//   doc.text(20, 105, 'Property Photo')
//   doc.setFontSize(12)
//   doc.text(30, 125, `Property_Photo: Property Photo`)

//   // Start Property Info
//   doc.setFontSize(16)
//   doc.text(20, 145, 'Property Info')
//   doc.setFontSize(12)
//   doc.text(30, 165, `Property Type: ${texts?.property?.property_type ?? ''}`)
//   doc.text(30, 180, `Bedrooms: ${texts?.property?.bedrooms ?? ''}`)
//   doc.text(30, 195, `Bathrooms: ${texts?.property?.bathrooms ?? ''}`)
//   doc.text(30, 210, `Area: ${texts?.property?.area ?? ''}`)
//   doc.text(30, 225, `Lot Size: ${texts?.property?.lotSize ?? ''}`)
//   doc.text(30, 240, `Year Built: ${texts?.property?.select_year ?? ''}`)

//   if (texts?.scenario?.length > 0) {
//     texts.scenario.map((item, index) => {
//       doc.addPage()
//       doc.setPage(index + 2)
//       doc.setFontSize(16)
//       doc.text(20, 20, `Home ${index + 1}`)
//       doc.text(20, 40, 'Hold Time')
//       doc.setFontSize(12)
//       doc.text(
//         30,
//         60,
//         `Hold Start Date: ${item?.hold_startDate ? moment(item?.hold_startDate, 'YYYY-MM-DD') : ''}`,
//       )
//       doc.text(
//         30,
//         75,
//         `Pay Off Date: ${item?.hold_payOffDate ? moment(item?.hold_payOffDate, 'YYYY-MM-DD') : ''}`,
//       )
//       doc.setFontSize(16)
//       doc.text(20, 95, 'Purchase Costs')
//       doc.setFontSize(12)
//       doc.text(
//         30,
//         115,
//         `Purchase Price: ${item?.purchase_purchasePrice ? `$ ${item.purchase_purchasePrice}` : ''}`,
//       )
//       doc.text(
//         30,
//         130,
//         `Appraisal Fees: ${item?.purchase_appraisalFee ? `$ ${item.purchase_appraisalFee}` : ''}`,
//       )
//       doc.text(
//         30,
//         145,
//         `Inspaction / Surveys fees: ${item?.purchase_inspectionFee ? `$ ${item.purchase_inspectionFee}` : ''}`,
//       )
//       if (item.isPccDollar) {
//         doc.text(
//           30,
//           160,
//           `Closing costs: ${item?.purchase_closingCostsNumeric ? `$ ${item.purchase_closingCostsNumeric}` : ''}`,
//         )
//       } else {
//         doc.text(
//           30,
//           160,
//           `Closing costs: ${item?.purchase_closingCostsPercentage ? `${item.purchase_closingCostsPercentage} %` : ''}`,
//         )
//       }

//       //Start Purchase Costs
//       doc.setFontSize(16)
//       doc.text(20, 180, 'Financing')
//       doc.setFontSize(12)
//       doc.text(
//         30,
//         200,
//         `Loan amount: ${item?.financing_loanAmount ? `$ ${item.financing_loanAmount}` : ''}`,
//       )
//       doc.text(
//         30,
//         215,
//         `Loan origination cost: ${item?.financing_loanOrigination ? `$ ${item.financing_loanOrigination}` : ''}`,
//       )
//       // doc.text(30, 230, `Interest rate: ${item?.interest_Rate ? `$ ${item.interest_Rate}` : ""}`)
//       if (item?.isIrrDollar) {
//         doc.text(
//           30,
//           245,
//           `Interest rate: ${item?.financing_interestRateNumeric ? `$ ${item.financing_interestRateNumeric}` : ''}`,
//         )
//       } else {
//         doc.text(
//           30,
//           245,
//           `Interest rate: ${item?.financing_interestRatePercentage ? `${item.financing_interestRatePercentage} %` : ''}`,
//         )
//       }

//       //Start Rehab Costs
//       doc.setFontSize(16)
//       doc.text(20, 265, 'Rehab Costs')
//       doc.setFontSize(12)
//       doc.text(30, 285, `Materials: ${item?.rehab_material ? `$ ${item.rehab_material}` : ''}`)
//       doc.text(
//         30,
//         300,
//         `Contractor/labor: ${item?.rehab_contractor ? `$ ${item.rehab_contractor}` : ''}`,
//       )

//       //Start Holding costs
//       doc.setFontSize(16)
//       doc.text(20, 320, 'Holding costs')
//       doc.setFontSize(12)
//       doc.text(
//         30,
//         340,
//         `Property texes: ${item?.holding_propertyTaxes ? `$ ${item.holding_propertyTaxes}` : ''}`,
//       )
//       doc.text(
//         30,
//         355,
//         `Insurance: ${item?.holding_insurance ? `$ ${item.holding_insurance}` : ''}`,
//       )
//       doc.text(
//         30,
//         370,
//         `Utilities: ${item?.holding_utilities ? `$ ${item.holding_utilities}` : ''}`,
//       )
//       doc.text(30, 385, `Other: ${item?.holding_other ? `$ ${item.holding_other}` : ''}`)

//       //Start Selling costs
//       doc.setFontSize(16)
//       doc.text(20, 405, 'Selling Cost')
//       doc.setFontSize(12)
//       doc.text(
//         30,
//         425,
//         `After repair value (ARV): ${item?.selling_afterRepairValue ? `$ ${item.selling_afterRepairValue}` : ''}`,
//       )
//       if (item.isCtaDollar) {
//         doc.text(
//           30,
//           440,
//           `Commission to agents: ${item?.selling_commissionToAgentsNumeric ? `$ ${item.selling_commissionToAgentsNumeric}` : ''}`,
//         )
//       } else {
//         doc.text(
//           30,
//           440,
//           `Commission to agents: ${item?.selling_commissionToAgentsPercentage ? `${item.selling_commissionToAgentsPercentage} %` : ''}`,
//         )
//       }
//       doc.text(
//         30,
//         455,
//         `Closing costs: ${item?.after_repairValue ? `$ ${item.after_repairValue}` : ''}`,
//       )

//       doc.setFontSize(16)
//       doc.text(20, 475, 'Caculations')
//       doc.setFontSize(12)
//       doc.text(
//         30,
//         495,
//         `Purchase costs: ${item?.purchase_costs ? `$ ${item.purchase_costs}` : ''}`,
//       )
//       doc.text(30, 515, `Rehab costs: ${item?.rehab_costs ? `$ ${item.rehab_costs}` : ''}`)
//       doc.text(30, 530, `Holding costs: ${item?.holding_costs ? `$ ${item.holding_costs}` : ''}`)
//       doc.text(
//         30,
//         545,
//         `Financing costs: ${item?.financing_costs ? `$ ${item.financing_costs}` : ''}`,
//       )
//       doc.text(30, 560, `Selling costs: ${item?.selling_costs ? `$ ${item.selling_costs}` : ''}`)
//       doc.text(
//         30,
//         575,
//         `Out of Pocket cost: ${item?.out_of_pocket_cost ? `$ ${item.out_of_pocket_cost}` : ''}`,
//       )
//       doc.text(30, 590, `Profit: ${item?.profit ? `$ ${item.profit}` : ''}`)
//       doc.text(30, 605, `ROI: ${item?.roi ? `${item.roi} %` : ''}`)
//       doc.text(
//         30,
//         620,
//         `Annualized ROI: ${item?.annualised_roi ? `${item.annualised_roi} %` : ''}`,
//       )
//       doc.text(30, 635, `xirr: ${item?.xirr ? `${item.xirr} %` : ''}`)
//     })
//   }

//   const pdfDataUri = doc.output('datauristring')
//   openInNewTab(pdfDataUri)
// }
