import React from 'react'

function LinkedIn() {
  return (
    <div>
      <svg
        width='24'
        height='25'
        viewBox='0 0 24 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.40001 24.5H0.299995V8.45H5.40001V24.5ZM2.85 6.2C1.2 6.2 0 5 0 3.35C0 1.7 1.35 0.5 2.85 0.5C4.5 0.5 5.7 1.7 5.7 3.35C5.7 5 4.5 6.2 2.85 6.2ZM24 24.5H18.9V15.8C18.9 13.25 17.85 12.5 16.35 12.5C14.85 12.5 13.35 13.7 13.35 15.95V24.5H8.25V8.45H13.05V10.7C13.5 9.65 15.3 8 17.85 8C20.7 8 23.7 9.65 23.7 14.6V24.5H24Z'
          fill='#2F2B43'
        fillOpacity='0.6'
        />
      </svg>
    </div>
  )
}

export default LinkedIn
