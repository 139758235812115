import { useEffect, useState } from 'react'
import HeroSection from '../../components/HeroSection'
import CreatedPropertyForm from '../../components/CreatedPropertyForm'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import ScrollingCard from '../../components/ScrollingCard'
import { showSuccessNotification } from '../../notifications/Success/notification'
import { showErrorNotification } from '../../notifications/Error/notification'

const Property = () => {
  const location = useLocation()
  const [initialLoading, setInitialLoading] = useState(false)
  const [pageData, setPageData] = useState({})
  const [property, setProperty] = useState({})
  const [propertyId, setPropertyId] = useState(null)
  const [initialValues, setInitialValues] = useState({})
  const [scenarios, setScenarios] = useState([])
  const [activeScenario, setActiveScenario] = useState(0)
  const [values, setValues] = useState({})
  const [disableRunScenario, setDisableRunScenario] = useState(true)
  const [triggerActiveScenario, setTriggerActiveScenario] = useState(false)
  const [activeScenarioProvidedValue, setActiveScenarioProvidedValue] = useState({})
  const [imgUrl, setImgUrl] = useState(null)
  const [imgId, setImgId] = useState(null)
  const [imgLoading, setImgLoading] = useState(null)
  const [loading, setLoading] = useState(false)
  const updatePropertyAndScenario = async (data, propertyId) => {
    let tempProperty = {},
      tempScenario = {}
    try {
      if (
        data?.property?.address &&
        data?.property?.city &&
        data?.property?.state &&
        data?.property?.zipcode
      ) {
        tempProperty = { ...data?.property }

        let config = {
          method: 'put',
          url: `${process.env.REACT_APP_BASE_URL}/property/${propertyId}`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(tempProperty),
        }

        const res = await axios.request(config)
        if (res?.status == 201 && res.data?.data?.id) {
          tempProperty = res.data.data
        }
        const scenarioRes = await createScenario(data.scenario)
        if (scenarioRes?.scenario?.id) {
          tempScenario = scenarioRes.scenario
          showSuccessNotification('Success', 'A New Scenario is Created')
        }
      }
    } catch (error) {
      console.log('error', error)
      showErrorNotification('Error', 'Error while creating scenario')
    }

    return { property: tempProperty, scenario: tempScenario }
  }
  const getPropertyAndScenarios = async (propertyId) => {
    setLoading(true)
    let tempProperty = {},
      tempScenarios = []
    try {
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/property/${propertyId}`,
      }

      const res = await axios.request(config)
      if (res?.status == '200' && res?.data?.data?.id) {
        setPropertyId(res?.data?.data?.id)
        const tempPropertyId = res?.data?.data?.id
        delete res?.data?.data?.id
        if (res?.data?.data?.file_id) {
          try {
            const file = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/file/${res.data.data.file_id}`,
            )
            if (file?.data?.file?.url && file?.data?.file?.id) {
              setImgId(file.data.file.id)
              setImgUrl(file.data.file.url)
            }
          } catch (error) {
            console.log('error in file')
          }
        }
        setProperty(res?.data?.data)
        tempProperty = res?.data.data
        let scenarioConfig = {
          method: 'get',
          url: `${process.env.REACT_APP_BASE_URL}/scenario/property/${tempPropertyId}`,
        }

        const scenarioRes = await axios.request(scenarioConfig)
        if (scenarioRes?.status == 200 && scenarioRes?.data?.length > 0) {
          tempScenarios = scenarioRes?.data.map((item) => {
            if (item.purchase_closingCostsPercentage?.toString()) {
              item.purchase_closingCostsPercentage = parseFloat(
                item.purchase_closingCostsPercentage,
              )
            }
            if (item.financing_interestRatePercentage?.toString()) {
              item.financing_interestRatePercentage = parseFloat(
                item.financing_interestRatePercentage,
              )
            }
            if (item.financing_loanOriginationPercentage?.toString()) {
              item.financing_loanOriginationPercentage = parseFloat(
                item.financing_loanOriginationPercentage,
              )
            }
            if (item.selling_commissionToAgentsPercentage?.toString()) {
              item.selling_commissionToAgentsPercentage = parseFloat(
                item.selling_commissionToAgentsPercentage,
              )
            }
            if (item.selling_closingCostPercentage?.toString()) {
              item.selling_closingCostPercentage = parseFloat(item.selling_closingCostPercentage)
            }
            if (item.roi?.toString()) {
              item.roi = parseFloat(item.roi)
            }
            if (item.annualised_roi?.toString()) {
              item.annualised_roi = parseFloat(item.annualised_roi)
            }
            if (item.xirr?.toString()) {
              item.xirr = parseFloat(item.xirr)
            }
            return item
          })
        }
      } else {
        showErrorNotification('Error', 'Reuested Property does not exist')
      }
    } catch (error) {
      console.log('error', error)
    }
    if (tempScenarios.length >= 3) {
      if (tempScenarios[tempScenarios.length - 1]['id']) {
        setActiveScenario(tempScenarios[tempScenarios.length - 1]['id'])
      }
    } else {
      tempScenarios.push({})
      setActiveScenario(0)
    }
    setScenarios([...tempScenarios])
    setLoading(false)
    return { property: tempProperty, scenarios: tempScenarios }
  }
  const createScenario = async (data) => {
    let tempScenario = {}
    try {
      data.property_id = propertyId
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/scenario`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(data),
      }

      const tempScenarioRes = await axios.request(config)
      if (tempScenarioRes?.data?.id) {
        tempScenario = tempScenarioRes?.data
        if (tempScenario.purchase_closingCostsPercentage?.toString()) {
          tempScenario.purchase_closingCostsPercentage = parseFloat(
            tempScenario.purchase_closingCostsPercentage,
          )
        }
        if (tempScenario.financing_interestRatePercentage?.toString()) {
          tempScenario.financing_interestRatePercentage = parseFloat(
            tempScenario.financing_interestRatePercentage,
          )
        }
        if (tempScenario.financing_loanOriginationPercentage?.toString()) {
          tempScenario.financing_loanOriginationPercentage = parseFloat(
            tempScenario.financing_loanOriginationPercentage,
          )
        }
        if (tempScenario.selling_commissionToAgentsPercentage?.toString()) {
          tempScenario.selling_commissionToAgentsPercentage = parseFloat(
            tempScenario.selling_commissionToAgentsPercentage,
          )
        }
        if (tempScenario.selling_closingCostPercentage?.toString()) {
          tempScenario.selling_closingCostPercentage = parseFloat(
            tempScenario.selling_closingCostPercentage,
          )
        }
        if (tempScenario.roi?.toString()) {
          tempScenario.roi = parseFloat(tempScenario.roi)
        }
        if (tempScenario.annualised_roi?.toString()) {
          tempScenario.annualised_roi = parseFloat(tempScenario.annualised_roi)
        }
        if (tempScenario.xirr?.toString()) {
          tempScenario.xirr = parseFloat(tempScenario.xirr)
        }
      } else {
        showErrorNotification('Error', 'Error while creating scenario')
      }
    } catch (error) {
      console.log('error', error)
      showErrorNotification('Error', 'Error while creating scenario')
    }
    return { scenario: tempScenario }
  }
  const deleteScenario = async () => {
    let res
    try {
      if (activeScenario > 0) {
        let config = {
          method: 'delete',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_URL}/scenario/${activeScenario}`,
        }
        res = await axios.request(config)
        showSuccessNotification('Success', 'Scenario is Deleted')
      }
    } catch (error) {
      console.log('error in deleting scenario')
      showErrorNotification('Error', 'Error while deleting scenario')
    }
    return res
  }
  const changeScenarioValues = (value) => {
    setTriggerActiveScenario(true)
    setActiveScenarioProvidedValue({ id: value })
  }
  useEffect(() => {
    getPageData()
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])
  const getPageData = async () => {
    try {
      setInitialLoading(true)
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/fix-and-flips/1?populate=*`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.id && res?.data?.data?.attributes?.publishedAt != null) {
        console.log('done it ')
        setPageData(res.data.data.attributes)
      }
    } catch (error) {
      console.log('error')
    }

    setInitialLoading(false)
  }
  return (
    <div>
      <div className='relative'>
        <HeroSection data={pageData} />
        <CreatedPropertyForm
          property={property}
          setProperty={setProperty}
          propertyId={propertyId}
          setPropertyId={setPropertyId}
          activeScenario={activeScenario}
          setActiveScenario={setActiveScenario}
          scenarios={scenarios}
          setScenarios={setScenarios}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          updatePropertyAndScenario={updatePropertyAndScenario}
          getPropertyAndScenarios={getPropertyAndScenarios}
          createScenario={createScenario}
          setValues={setValues}
          disableRunScenario={disableRunScenario}
          setDisableRunScenario={setDisableRunScenario}
          deleteScenario={deleteScenario}
          triggerActiveScenario={triggerActiveScenario}
          activeScenarioProvidedValue={activeScenarioProvidedValue}
          setActiveScenarioProvidedValue={setActiveScenarioProvidedValue}
          setTriggerActiveScenario={setTriggerActiveScenario}
          showControls={true}
          imgUrl={imgUrl}
          setImgUrl={setImgUrl}
          imgId={imgId}
          setImgId={setImgId}
          imgLoading={imgLoading}
          setImgLoading={setImgLoading}
          loading={loading}
          setLoading={setLoading}
        />
        <ScrollingCard
          values={values}
          disableFields={false}
          scenarios={scenarios}
          property={property}
          activeScenario={0}
          onChange={changeScenarioValues}
          sharePdf={true}
          imgUrl={imgUrl}
          imgId={imgId}
        />
      </div>
    </div>
  )
}
export default Property
