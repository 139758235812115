import React, { createContext, useState, useContext } from 'react'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('email'))
  const login = (email) => {
    localStorage.setItem('email', email)
    setIsLoggedIn(true)
  }

  const logout = async () => {
    localStorage.removeItem('email')
    setIsLoggedIn(false)
  }
  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>{children}</AuthContext.Provider>
  )
}

export default AuthContext
