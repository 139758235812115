import React, { useState, useEffect } from 'react'
import {
  CopyOutlined,
  DownloadOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { Page, Text, View, Image, Document, StyleSheet, Link } from '@react-pdf/renderer'
import { BlobProvider } from '@react-pdf/renderer'
import FacebookIcon from '../../assets/svg/FacebookIcon'
import YouTubeIcon from '../../assets/svg/YouTubeIcon'
import Instagram from '../../assets/svg/Instagram'
import LinkedIn from '../../assets/svg/LinkedIn'
import Tiktok from '../../assets/svg/Tiktok'
import './style.scss'
import { Input, Button, Modal, Checkbox, message, Upload, Flex } from 'antd'
import moment from 'moment'
import { FacebookShareButton, LinkedinShareButton } from 'react-share'
import { useParams } from 'react-router'
import dayjs from 'dayjs'

function PdfSharingModal({ open, setOpen, scenarios1, property, imgUrl, imgId }) {
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [reportId, setReportId] = useState(false)
  const condition = false
  const [logoBase64, setLogoBase64] = useState(null)
  const [fileSize, setFileSize] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [enableSharing, setEnableSharing] = useState(false)

  const scenarios = scenarios1.filter((item) => item.id > 0)

  const handleBeforeUpload = (file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase()
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg']

    if (!allowedExtensions.includes(fileExtension)) {
      message.error('You can only upload JPG/PNG/SVG files!')
      return false
    }
    if (file && file.size) {
      if (file.size / 1024 / 1024 < 1) {
        setFileSize(true)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          setLogoBase64(reader.result)
        }
        return true // Allow upload
      } else {
        message.error('Logo must be smaller than 1MB!')
      }
    }
    return false // Prevent upload
  }

  const handleChange = (info) => {
    const file = info.file
    if (file.status === 'done') {
      setLogoBase64(file.response)
    }
  }

  const onChange = () => {
    setIsCopy(false)
    setEnableSharing((preVal) => !preVal)
  }

  const handleOk = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setOpen(false)
    }, 3000)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  // Create styles for PDF document
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: 'transparent', // Make the page background transparent to see the gradient
    },
    backgroundImage: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    main: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      padding: 40,
      fontSize: 12,
      flexDirection: 'column',
      justifyContent: 'center',
      border: '1px solid black',
    },
    mainHeading: {
      fontSize: 18,
      fontWeight: 'bold',
    },

    heading: {
      fontSize: 14,
      fontWeight: 'bold',
    },

    text: {
      fontSize: 12,
      color: '#475467',
    },
    logoBar: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    defaultLogo: {
      display: 'flex',
      flexDirection: 'row',
    },
    logo: {
      width: 40,
      height: 30,
      border: '1px solid black',
    },
    informationLine: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: 4,
    },
    propertyInformation: {
      display: 'flex',
      flexDirection: 'row',
      width: '46%',
      // justifyContent: 'space-between',
      color: '#475467',
    },
    informationHeading: {
      textAlign: 'right',
      width: '80px',
    },
    info: {
      paddingLeft: '6px',
    },
    info1: {
      paddingLeft: '9px',
    },
    tableLine: {
      flexDirection: 'row',
      width: '100%',
      borderBottomWidth: '1px',
      borderBottomColor: '#475467',
    },
    tableCell: {
      paddingVertical: 1,
      display: 'flex',
      width: '23%',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: '1px',
      borderRightColor: '#475467',
      color: '#475467',
    },
    tableFistCell: {
      paddingVertical: 1,
      display: 'flex',
      width: '31%',
      borderRight: '1px',
      color: '#475467',
      borderRightColor: '#475467',
      color: '#475467',
    },
    tableCell1: {
      paddingVertical: 1,
      display: 'flex',
      width: '33%',
      borderRight: '1px',
      borderRightColor: '#475467',
      color: '#475467',
    },
    tableFistCell1: {
      paddingVertical: 1,
      display: 'flex',
      width: '34%',
      borderRight: '1px',
      borderRightColor: '#475467',
      color: '#475467',
    },
    tableCell2: {
      paddingVertical: 1,
      display: 'flex',
      width: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: '1px',
      borderRightColor: '#475467',
    },
    tableFistCell2: {
      paddingVertical: 1,
      display: 'flex',
      width: '50%',
      borderRight: '1px',
      borderRightColor: '#475467',
      color: '#475467',
    },
    cellText: {
      textAlign: 'center',
      color: '#475467',
    },
    name: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    address: {
      flexDirection: 'column',
      marginTop: 15,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: 2,
      marginTop: 20,
      marginBottom: 20,
    },
    col: {
      display: 'flex',
      flexDirection: 'row',
    },

    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 6,
      marginLeft: 12,
    },
    footer: {
      marginTop: 'auto',
      borderTop: '1px solid black',
      paddingTop: 5,
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 5,
    },
    footertxt: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    footerlnk: {
      fontSize: 12,
      fontWeight: 'bold',
    },
  })

  const MyDocument = ({ property, scenarios, logoBase64 }) => (
    <Document>
      <Page size='A4' style={styles.page}>
        <Image src='/pdfBg.jpg' style={styles.backgroundImage} />
        <View style={styles.main}>
          <View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {/* heder bar */}
              <View style={[styles.logoBar, { alignItems: 'baseline', width: '30%' }]}>
                {logoBase64 ? (
                  <Image src={logoBase64} style={styles.logo} />
                ) : (
                  <View style={styles.defaultLogo}>
                    <Image src='/pdfLogo2.jpg' style={styles.logo} />
                    {/* <Text style={{ fontSize: 16, fontWeight: 'bold' }}>Blu Hat Bookkeeping</Text> */}
                  </View>
                )}
              </View>
              {/* title bar */}
              <View
                style={[
                  styles.logoBar,
                  styles.mainHeading,
                  { justifyContent: 'center', paddingTop: 30, width: '40%' },
                ]}
              >
                <Text style={{}}>Scenario Fix And Flip</Text>
              </View>
              <View
                style={{
                  width: '30%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                }}
              >
                {/* {imgId && imgUrl ? (
                  <Image
                    src='/pdfBg.jpg'
                    style={{ width: '80px', height: '50px', alignItems: 'baseline' }}
                  />
                ) : (
                  ''
                )} */}
              </View>
            </View>
            {/* property address */}
            <View
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                marginTop: 16,
              }}
            >
              <Text style={styles.heading}>Property’s Address</Text>
              <View style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                <Text style={[styles.text, { minWidth: '90px' }]}>Street Address:</Text>
                <Text style={styles.text}>{property?.address}</Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 4,
                  marginBottom: 16,
                  width: '100%',
                }}
              >
                <View style={[styles.text, { display: 'flex', flexDirection: 'row' }]}>
                  <Text style={[styles.text, { minWidth: '30px' }]}>City:</Text>
                  <Text>{property?.city} </Text>
                </View>
                <View
                  style={[
                    styles.text,
                    { display: 'flex', flexDirection: 'row', marginLeft: 40, marginRight: 40 },
                  ]}
                >
                  <Text style={[styles.text, { minWidth: '40px' }]}>State:</Text>
                  <Text>{property?.state} </Text>
                </View>
                <View style={[styles.text, { display: 'flex', flexDirection: 'row' }]}>
                  <Text style={[styles.text, { minWidth: '30px' }]}>Zip:</Text>
                  <Text>{property?.zipcode}</Text>
                </View>
              </View>
            </View>
            {/* property information */}
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={styles.heading}>Property Information</Text>
              <View>
                <View style={styles.informationLine}>
                  <View style={styles.propertyInformation}>
                    <Text style={styles.informationHeading}>Property Type:</Text>
                    <Text style={styles.info}> {property?.type ?? 'N/A'}</Text>
                  </View>
                  <View style={styles.propertyInformation}>
                    <Text style={[{ paddingRight: 4 }, styles.informationHeading]}>Bedrooms:</Text>
                    <Text style={styles.info}> {property?.bedrooms ?? 'N/A'}</Text>
                  </View>
                </View>
                <View style={styles.informationLine}>
                  <View style={styles.propertyInformation}>
                    <Text style={styles.informationHeading}>Bathrooms:</Text>
                    <Text style={styles.info}> {property?.bathrooms ?? 'N/A'}</Text>
                  </View>
                  <View style={styles.propertyInformation}>
                    <Text style={styles.informationHeading}>SQ FT:</Text>
                    <Text style={styles.info}>
                      {' '}
                      {property?.area ? `${property.area} sq ft` : 'N/A'}
                    </Text>
                  </View>
                </View>
                <View style={styles.informationLine}>
                  <View style={styles.propertyInformation}>
                    <Text style={styles.informationHeading}>Lot Size:</Text>
                    <Text style={styles.info}>
                      {' '}
                      {property?.lotSize ? `${property.lotSize} sq ft` : 'N/A'}
                    </Text>
                  </View>
                  <View style={styles.propertyInformation}>
                    <Text style={styles.informationHeading}>Year Build:</Text>
                    <Text style={styles.info1}>
                      {property?.yearBuild ? property.yearBuild : 'N/A'}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {/* table for three Scenario */}
            {scenarios.length === 3 && (
              <View style={{ marginTop: 20 }}>
                {/* date lines */}
                <View
                  style={[styles.tableLine, { borderTopWidth: '1px', borderTopColor: '#475467' }]}
                >
                  <View style={[{ alignItems: 'flex' }, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start' }}>Start Date</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>{scenarios[0]?.hold_startDate}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>{scenarios[1]?.hold_startDate}</Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[2]?.hold_startDate}</Text>
                  </View>
                </View>
                <View style={styles.tableLine}>
                  <View style={[{ alignItems: 'flex' }, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start' }}>End Date</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>{scenarios[0]?.hold_payOffDate}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>{scenarios[1]?.hold_payOffDate}</Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[2]?.hold_payOffDate}</Text>
                  </View>
                </View>
                {/* Out of Pockets Cost */}
                {/* main heading line */}

                <View style={styles.tableLine}>
                  <View style={[{ alignItems: 'flex' }, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>
                      Out of Pockets Cost
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>Scenario # 1 </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>Scenario # 2</Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>Scenario # 3</Text>
                  </View>
                </View>
                {/* Purchase Price */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Purchase Price
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.purchase_purchasePrice.toLocaleString()}{' '}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.purchase_purchasePrice?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[2]?.purchase_purchasePrice?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Purchase Costs */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Purchase Costs
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.purchase_costs?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.purchase_costs?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[2]?.purchase_costs?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Rehab Costs */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Rehab Costs
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.rehab_costs?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.rehab_costs?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[2]?.rehab_costs?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Holding Costs */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Holding Costs
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_costs?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.holding_costs?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[2]?.holding_costs?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Loan Amount */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Loan Amount
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.financing_loanAmount?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.financing_loanAmount?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[2]?.financing_loanAmount?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* key Metrics */}
                {/* main heading line */}

                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Key Metrics</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.rehab_material?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[1]?.rehab_material?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[2]?.rehab_material?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Profit  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Profit</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.profit ? `$ ${scenarios[0]?.profit?.toLocaleString()}` : ''}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      {scenarios[1]?.profit ? `$ ${scenarios[1]?.profit?.toLocaleString()}` : ''}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[2]?.profit ? `$ ${scenarios[2]?.profit?.toLocaleString()}` : ''}
                    </Text>
                  </View>
                </View>
                {/* ROI  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>ROI</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>{scenarios[0]?.roi}%</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>{scenarios[1]?.roi}%</Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[2]?.roi}%</Text>
                  </View>
                </View>
                {/* Annualized ROI  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Annualized ROI
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>{scenarios[0]?.annualised_roi}%</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>{scenarios[1]?.annualised_roi}%</Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[2]?.annualised_roi}%</Text>
                  </View>
                </View>
                {/* IRR  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>IRR</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>{scenarios[0]?.xirr}%</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>{scenarios[1]?.xirr}%</Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[2]?.xirr}%</Text>
                  </View>
                </View>
                {/* Purchase Costs */}
                {/* main heading line */}

                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Purchase Costs</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.purchase_purchasePrice?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[1]?.purchase_purchasePrice?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[2]?.purchase_purchasePrice?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Appraisals  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Appraisals</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.purchase_appraisalFee?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.purchase_appraisalFee?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[2]?.purchase_appraisalFee?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Inspection/Survey  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Inspection/Survey
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.purchase_inspectionFee?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.purchase_inspectionFee?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[2]?.purchase_inspectionFee?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Closing Costs  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Closing Costs
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isPccDollar
                        ? ` $ ${scenarios[0]?.purchase_closingCostsNumeric?.toLocaleString()}`
                        : `${scenarios[0]?.purchase_closingCostsPercentage}%`}
                    </Text>{' '}
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      {scenarios[1]?.isPccDollar
                        ? ` $ ${scenarios[1]?.purchase_closingCostsNumeric?.toLocaleString()}`
                        : `${scenarios[1]?.purchase_closingCostsPercentage}%`}
                    </Text>{' '}
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[2]?.isPccDollar
                        ? ` $ ${scenarios[2]?.purchase_closingCostsNumeric?.toLocaleString()}`
                        : `${scenarios[2]?.purchase_closingCostsPercentage}%`}
                    </Text>{' '}
                  </View>
                </View>
                {/*Rehab Costs */}
                {/* main heading line */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Rehab Costs</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.rehab_costs?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[1]?.rehab_costs?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[2]?.rehab_costs?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Materials  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Materials</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.rehab_material?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.rehab_material?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[2]?.rehab_material?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Contract/Labor  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Contract/Labor
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>${scenarios[0]?.rehab_contractor}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>${scenarios[1]?.rehab_contractor}</Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>${scenarios[2]?.rehab_contractor}</Text>
                  </View>
                </View>
                {/*Holding Costs */}
                {/* main heading line */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Holding Costs</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.holding_costs?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[1]?.holding_costs?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[2]?.holding_costs?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Interest Payments  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Interest Payments
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isIrrDollar ? (
                        <Text style={styles.cellText}>
                          ${scenarios[0]?.financing_interestRateNumeric?.toLocaleString()}
                        </Text>
                      ) : (
                        <Text style={styles.cellText}>
                          {scenarios[0]?.financing_interestRatePercentage}%
                        </Text>
                      )}
                    </Text>{' '}
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      {scenarios[1]?.isIrrDollar ? (
                        <Text style={styles.cellText}>
                          ${scenarios[1]?.financing_interestRateNumeric?.toLocaleString()}
                        </Text>
                      ) : (
                        <Text style={styles.cellText}>
                          {scenarios[1]?.financing_interestRatePercentage}%
                        </Text>
                      )}
                    </Text>{' '}
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[2]?.isIrrDollar ? (
                        <Text style={styles.cellText}>
                          ${scenarios[2]?.financing_interestRateNumeric?.toLocaleString()}
                        </Text>
                      ) : (
                        <Text style={styles.cellText}>
                          {scenarios[2]?.financing_interestRatePercentage}%
                        </Text>
                      )}
                    </Text>{' '}
                  </View>
                </View>
                {/* Loan Origination Cost  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Loan Origination Cost
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isLocDollar
                        ? `$ ${scenarios[0]?.financing_loanOriginationNumeric?.toLocaleString()}`
                        : `${scenarios[0]?.financing_loanOriginationPercentage}%`}
                    </Text>{' '}
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      {scenarios[1]?.isLocDollar
                        ? `$ ${scenarios[1]?.financing_loanOriginationNumeric?.toLocaleString()}`
                        : `${scenarios[1]?.financing_loanOriginationPercentage}%`}
                    </Text>{' '}
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[2]?.isLocDollar
                        ? `$ ${scenarios[2]?.financing_loanOriginationNumeric?.toLocaleString()}`
                        : `${scenarios[2]?.financing_loanOriginationPercentage}%`}
                    </Text>{' '}
                  </View>
                </View>
                {/* Property Taxes  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Property Taxes
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_propertyTaxes?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.holding_propertyTaxes?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[2]?.holding_propertyTaxes?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Insurance  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Insurance</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_insurance?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.holding_insurance?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[2]?.holding_insurance?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Utilities  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Utilities</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_utilities?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.holding_utilities?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[2]?.holding_utilities?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Others  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Others</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_other?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.holding_other?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[2]?.holding_other?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/*Selling Costs */}
                {/* main heading line */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Selling Costs</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.selling_costs?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[1]?.selling_costs?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[2]?.selling_costs?.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Commission  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Commission</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isCtaDollar
                        ? `$ ${scenarios[0].selling_commissionToAgentsNumeric?.toLocaleString()}`
                        : `${scenarios[0]?.selling_commissionToAgentsPercentage}%`}
                    </Text>{' '}
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      {scenarios[1]?.isCtaDollar
                        ? `$ ${scenarios[1].selling_commissionToAgentsNumeric?.toLocaleString()}`
                        : `${scenarios[1]?.selling_commissionToAgentsPercentage}%`}
                    </Text>{' '}
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[2]?.isCtaDollar
                        ? `$ ${scenarios[2].selling_commissionToAgentsNumeric?.toLocaleString()}`
                        : `${scenarios[2]?.selling_commissionToAgentsPercentage}%`}
                    </Text>{' '}
                  </View>
                </View>
                {/* Commission  */}
                {/* <View style={styles.tableLine}>
            <View style={[{}, styles.tableFistCell]}>
              <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Commission</Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.cellText}> </Text>
            </View>
            <View style={styles.tableCell}>
              <Text style={styles.cellText}></Text>
            </View>
            <View style={[styles.tableCell, { borderRight: '0px' }]}>
              <Text style={styles.cellText}></Text>
            </View>
          </View> */}
                {/* Closing Costs  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Closing Costs
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isSccDollar
                        ? `$ ${scenarios[0]?.selling_closingCostNumeric}`
                        : ` ${scenarios[0]?.selling_closingCostPercentage}%`}
                    </Text>{' '}
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={styles.cellText}>
                      {scenarios[1]?.isSccDollar
                        ? `$ ${scenarios[1]?.selling_closingCostNumeric}`
                        : ` ${scenarios[1]?.selling_closingCostPercentage}%`}
                    </Text>{' '}
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[2]?.isSccDollar
                        ? `$ ${scenarios[2]?.selling_closingCostNumeric}`
                        : ` ${scenarios[2]?.selling_closingCostPercentage}%`}
                    </Text>{' '}
                  </View>
                </View>
                {/* After Repair Value  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>
                      After Repair Value
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.selling_afterRepairValue?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[1]?.selling_afterRepairValue?.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[2]?.selling_afterRepairValue?.toLocaleString()}
                    </Text>
                  </View>
                </View>
              </View>
            )}
            {/* //////////////////////////////////////////////// */}
            {/* table for two Scenario */}
            {scenarios.length === 2 && (
              <View style={{ marginTop: 20 }}>
                {/* date lines */}
                <View
                  style={[styles.tableLine, { borderTopWidth: '1px', borderTopColor: '#475467' }]}
                >
                  <View style={[{ alignItems: 'flex' }, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start' }}>Start Date</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>{scenarios[0]?.hold_startDate}</Text>
                  </View>
                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[1]?.hold_startDate}</Text>
                  </View>
                </View>
                <View style={styles.tableLine}>
                  <View style={[{ alignItems: 'flex' }, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start' }}>End Date</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>{scenarios[0]?.hold_payOffDate}</Text>
                  </View>
                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[1]?.hold_payOffDate}</Text>
                  </View>
                </View>
                {/* Out of Pockets Cost */}
                {/* main heading line */}

                <View style={styles.tableLine}>
                  <View style={[{ alignItems: 'flex' }, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>
                      Out of Pockets Cost
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={[styles.cellText, { color: 'black' }]}>Scenario # 1 </Text>
                  </View>
                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>Scenario # 2</Text>
                  </View>
                </View>
                {/* Purchase Price */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Purchase Price
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.purchase_purchasePrice.toLocaleString()}{' '}
                    </Text>
                  </View>
                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.purchase_purchasePrice.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Purchase Costs */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Purchase Costs
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.purchase_costs.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.purchase_costs.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Rehab Costs */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Rehab Costs
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.rehab_costs.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.rehab_costs.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Holding Costs */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Holding Costs
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_costs.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.holding_costs.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Loan Amount */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Loan Amount
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.financing_loanAmount.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.financing_loanAmount.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* key Metrics */}
                {/* main heading line */}

                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Key Metrics</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.rehab_material.toLocaleString()}
                    </Text>
                  </View>
                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[1]?.rehab_material.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Profit  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Profit</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.profit ? `$ ${scenarios[0]?.profit.toLocaleString()}` : ''}
                    </Text>{' '}
                  </View>
                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[1]?.profit ? `$ ${scenarios[1]?.profit.toLocaleString()}` : ''}
                    </Text>{' '}
                  </View>
                </View>
                {/* ROI  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>ROI</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>{scenarios[0]?.roi}%</Text>
                  </View>
                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[1]?.roi}%</Text>
                  </View>
                </View>
                {/* Annualized ROI  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Annualized ROI
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>{scenarios[0]?.annualised_roi}%</Text>
                  </View>
                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[1]?.annualised_roi}%</Text>
                  </View>
                </View>
                {/* IRR  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>IRR</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>{scenarios[0]?.xirr}%</Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[1]?.xirr}%</Text>
                  </View>
                </View>
                {/* Purchase Costs */}
                {/* main heading line */}

                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Purchase Costs</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.purchase_purchasePrice.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[1]?.purchase_purchasePrice.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Appraisals  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Appraisals</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.purchase_appraisalFee.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.purchase_appraisalFee.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Inspection/Survey  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Inspection/Survey
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.purchase_inspectionFee.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.purchase_inspectionFee.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Closing Costs  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Closing Costs
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isPccDollar
                        ? ` $ ${scenarios[0]?.purchase_closingCostsNumeric.toLocaleString()}`
                        : `${scenarios[0]?.purchase_closingCostsPercentage}%`}
                    </Text>{' '}
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[1]?.isPccDollar
                        ? ` $ ${scenarios[1]?.purchase_closingCostsNumeric.toLocaleString()}`
                        : `${scenarios[1]?.purchase_closingCostsPercentage}%`}
                    </Text>{' '}
                  </View>
                </View>
                {/*Rehab Costs */}
                {/* main heading line */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Rehab Costs</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.rehab_costs.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[1]?.rehab_costs.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Materials  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Materials</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.rehab_material.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.rehab_material.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Contract/Labor  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Contract/Labor
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.rehab_contractor.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.rehab_contractor.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/*Holding Costs */}
                {/* main heading line */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Holding Costs</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.holding_costs.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[1]?.holding_costs.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Interest Payments  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Interest Payments
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isIrrDollar ? (
                        <Text style={styles.cellText}>
                          ${scenarios[0]?.financing_interestRateNumeric.toLocaleString()}
                        </Text>
                      ) : (
                        <Text style={styles.cellText}>
                          {scenarios[0]?.financing_interestRatePercentage}%
                        </Text>
                      )}
                    </Text>{' '}
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[1]?.isIrrDollar ? (
                        <Text style={styles.cellText}>
                          ${scenarios[1]?.financing_interestRateNumeric.toLocaleString()}
                        </Text>
                      ) : (
                        <Text style={styles.cellText}>
                          {scenarios[1]?.financing_interestRatePercentage}%
                        </Text>
                      )}
                    </Text>{' '}
                  </View>
                </View>
                {/* Loan Origination Cost  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Loan Origination Cost
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isLocDollar
                        ? `$ ${scenarios[0]?.financing_loanOriginationNumeric.toLocaleString()}`
                        : `${scenarios[0]?.financing_loanOriginationPercentage}%`}
                    </Text>{' '}
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[1]?.isLocDollar
                        ? `$ ${scenarios[1]?.financing_loanOriginationNumeric.toLocaleString()}`
                        : `${scenarios[1]?.financing_loanOriginationPercentage}%`}
                    </Text>{' '}
                  </View>
                </View>
                {/* Property Taxes  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Property Taxes
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_propertyTaxes.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.holding_propertyTaxes.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Insurance  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Insurance</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_insurance.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.holding_insurance.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Utilities  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Utilities</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_utilities.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.holding_utilities.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Others  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Others</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_other.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[1]?.holding_other.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/*Selling Costs */}
                {/* main heading line */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Selling Costs</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.selling_costs.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[1]?.selling_costs.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Commission  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Commission</Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      {scenarios[0].isCtaDollar
                        ? `$ ${scenarios[0]?.selling_commissionToAgentsNumeric.toLocaleString()}`
                        : `${scenarios[0]?.selling_commissionToAgentsPercentage}%`}
                    </Text>{' '}
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[1].isCtaDollar
                        ? `$ ${scenarios[1]?.selling_commissionToAgentsNumeric.toLocaleString()}`
                        : `${scenarios[1]?.selling_commissionToAgentsPercentage}%`}
                    </Text>{' '}
                  </View>
                </View>
                {/* Commission  */}
                {/* <View style={styles.tableLine}>
            <View style={[{}, styles.tableFistCell1]}>
              <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Commission</Text>
            </View>
            <View style={styles.tableCell1}>
              <Text style={styles.cellText}> </Text>
            </View>

            <View style={[styles.tableCell1, { borderRight: '0px' }]}>
              <Text style={styles.cellText}></Text>
            </View>
          </View> */}
                {/* Closing Costs  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Closing Costs
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isSccDollar
                        ? `$ ${scenarios[0]?.selling_closingCostNumeric.toLocaleString()}`
                        : ` ${scenarios[0]?.selling_closingCostPercentage}%`}
                    </Text>{' '}
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[1]?.isSccDollar
                        ? `$ ${scenarios[1]?.selling_closingCostNumeric.toLocaleString()}`
                        : ` ${scenarios[1]?.selling_closingCostPercentage}%`}
                    </Text>{' '}
                  </View>
                </View>
                {/* After Repair Value  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell1]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>
                      After Repair Value
                    </Text>
                  </View>
                  <View style={styles.tableCell1}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.selling_afterRepairValue.toLocaleString()}
                    </Text>
                  </View>

                  <View style={[styles.tableCell1, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[1]?.selling_afterRepairValue.toLocaleString()}
                    </Text>
                  </View>
                </View>
              </View>
            )}
            {/* //////////////////////////////////////////////// */}
            {/* table for one Scenario */}
            {scenarios.length === 1 && (
              <View style={{ marginTop: 20 }}>
                {/* date lines */}
                <View
                  style={[styles.tableLine, { borderTopWidth: '1px', borderTopColor: '#475467' }]}
                >
                  <View style={[{ alignItems: 'flex' }, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start' }}>Start Date</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[0]?.hold_startDate}</Text>
                  </View>
                </View>
                <View style={styles.tableLine}>
                  <View style={[{ alignItems: 'flex' }, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start' }}>End Date</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[0]?.hold_payOffDate}</Text>
                  </View>
                </View>
                {/* Out of Pockets Cost */}
                {/* main heading line */}

                <View style={styles.tableLine}>
                  <View style={[{ alignItems: 'flex' }, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>
                      Out of Pockets Cost
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>Scenario # 1</Text>
                  </View>
                </View>
                {/* Purchase Price */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Purchase Price
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.purchase_purchasePrice.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Purchase Costs */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Purchase Costs
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.purchase_costs.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Rehab Costs */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Rehab Costs
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.rehab_costs.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Holding Costs */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Holding Costs
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_costs.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Loan Amount */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Loan Amount
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.financing_loanAmount.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* key Metrics */}
                {/* main heading line */}

                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Key Metrics</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.rehab_material.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Profit  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Profit</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.profit ? `$ ${scenarios[0]?.profit.toLocaleString()}` : ''}
                    </Text>{' '}
                  </View>
                </View>
                {/* ROI  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>ROI</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[0]?.roi}%</Text>
                  </View>
                </View>
                {/* Annualized ROI  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Annualized ROI
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[0]?.annualised_roi}%</Text>
                  </View>
                </View>
                {/* IRR  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>IRR</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>{scenarios[0]?.xirr}%</Text>
                  </View>
                </View>
                {/* Purchase Costs */}
                {/* main heading line */}

                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Purchase Costs</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.purchase_purchasePrice.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Appraisals  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Appraisals</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.purchase_appraisalFee.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Inspection/Survey  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Inspection/Survey
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.purchase_inspectionFee.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Closing Costs  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Closing Costs
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isPccDollar
                        ? ` $ ${scenarios[0]?.purchase_closingCostsNumeric.toLocaleString()}`
                        : `${scenarios[0]?.purchase_closingCostsPercentage}%`}
                    </Text>
                  </View>
                </View>
                {/*Rehab Costs */}
                {/* main heading line */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Rehab Costs</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.rehab_costs.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Materials  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Materials</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.rehab_material.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Contract/Labor  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Contract/Labor
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.rehab_contractor.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/*Holding Costs */}
                {/* main heading line */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Holding Costs</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.holding_costs.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Interest Payments  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Interest Payments
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isIrrDollar ? (
                        <Text style={styles.cellText}>
                          ${scenarios[0]?.financing_interestRateNumeric.toLocaleString()}
                        </Text>
                      ) : (
                        <Text style={styles.cellText}>
                          {scenarios[0]?.financing_interestRatePercentage}%
                        </Text>
                      )}
                    </Text>
                  </View>
                </View>
                {/* Loan Origination Cost  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Loan Origination Cost
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isLocDollar
                        ? `$ ${scenarios[0]?.financing_loanOriginationNumeric.toLocaleString()}`
                        : `${scenarios[0]?.financing_loanOriginationPercentage}%`}
                    </Text>
                  </View>
                </View>
                {/* Property Taxes  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Property Taxes
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_propertyTaxes.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Insurance  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Insurance</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_insurance.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Utilities  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Utilities</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_utilities.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/* Others  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Others</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      ${scenarios[0]?.holding_other.toLocaleString()}
                    </Text>
                  </View>
                </View>
                {/*Selling Costs */}
                {/* main heading line */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>Selling Costs</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.selling_costs.toLocaleString()}
                    </Text>
                  </View>
                </View>

                {/* Commission  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>Commission</Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isCtaDollar
                        ? `$ ${scenarios[0].selling_commissionToAgentsNumeric.toLocaleString()}`
                        : `${scenarios[0]?.selling_commissionToAgentsPercentage}%`}
                    </Text>
                  </View>
                </View>
                {/* Closing Costs  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', marginLeft: '24px' }}>
                      Closing Costs
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={styles.cellText}>
                      {scenarios[0]?.isSccDollar
                        ? `$ ${scenarios[0]?.selling_closingCostNumeric.toLocaleString()}`
                        : ` ${scenarios[0]?.selling_closingCostPercentage}%`}
                    </Text>
                  </View>
                </View>
                {/* After Repair Value  */}
                <View style={styles.tableLine}>
                  <View style={[{}, styles.tableFistCell2]}>
                    <Text style={{ alignItems: 'flex-start', color: 'black' }}>
                      After Repair Value
                    </Text>
                  </View>

                  <View style={[styles.tableCell2, { borderRight: '0px' }]}>
                    <Text style={[styles.cellText, { color: 'black' }]}>
                      ${scenarios[0]?.selling_afterRepairValue.toLocaleString()}
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footertxt}>
            Create your free bookkeeping scenario at{' '}
            <Link src='https://bluhatbookkepping.com/' style={styles.footerlnk}>
              BluHatBookKeeping.com
            </Link>{' '}
            or book a free call to simplify your finances now!
          </Text>
        </View>
      </Page>
    </Document>
  )

  const openInNewTab = (dataUri) => {
    const newWindow = window.open()
    newWindow.document.write('<iframe width="100%" height="100%" src="' + dataUri + '"></iframe>')
  }
  useEffect(() => {
    if (params?.id) {
      setReportId(params.id)
    }
  }, [params])
  return (
    <div>
      <Modal
        visible={open}
        title='Sharing & PDF Settings'
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <p>
          User Name <span style={{ color: '#7F56D9' }}>sksk@demo.com</span>
        </p>
        <p>Last updated {dayjs(property.updatedAt).format('YYYY-MM-DD')}</p>

        <div className='font-medium text-sm mt-4'>
          <Checkbox onChange={onChange}>Enable report sharing</Checkbox>
        </div>

        <div>
          <Checkbox onChange={onChange}>
            <p className='font-medium text-sm'>Show property address when sharing</p>
          </Checkbox>
          <p className='ml-6'>You can enable or disable sharing of this report at any time.</p>
        </div>

        <div className='mt-3 ml-6'>
          <p className='italic'>
            By enabling report sharing, you acknowledge and agree that your report will be made
            available to any parties to whom you provide access.
          </p>
        </div>

        <div className='font-medium text-sm mt-4'>
          <p className='mb-2'>Link to this report</p>
          <Input
            value={
              enableSharing
                ? `${window.location.protocol}//${window.location.host}/flipProperty/${reportId}`
                : ''
            }
            addonAfter={
              isCopy && enableSharing ? (
                <span className='-mx-1'>✔️</span>
              ) : (
                <CopyOutlined
                  onClick={() => {
                    setIsCopy(true)
                    navigator.clipboard.writeText(
                      `${window.location.protocol}//${window.location.host}/flipProperty/${reportId}`,
                    )
                  }}
                />
              )
            }
          />
        </div>

        <div className='mt-4' style={{ width: '100%' }}>
          <p className='mb-2 font-medium text-sm'>Add a logo to your PDF report</p>
          <Upload
            className='full-width-upload'
            // action='/your-upload-url'
            beforeUpload={handleBeforeUpload}
            onChange={handleChange}
            showUploadList={false}
            accept='.jpg,.png,.svg'
          >
            {logoBase64 ? null : (
              <Button
                type='dashed'
                block
                className='flex items-center justify-between h-12'
                style={{ borderColor: '#7F56D9' }}
              >
                <span style={{ color: '#7F56D9' }}>+ Upload Logo</span>
                <CloudDownloadOutlined style={{ color: '#7F56D9' }} />
              </Button>
            )}
          </Upload>
          {logoBase64 ? (
            <div className='flex w-full justify-between border rounded-md h-12'>
              <img src={logoBase64} className='w-18 h-full rounded-l-md' />
              <button
                onClick={() => {
                  setLogoBase64(null)
                }}
              >
                <DeleteOutlined className='flex justify-center items-center w-9 h-full border-l' />
              </button>
            </div>
          ) : (
            ''
          )}
          <p>You can only upload a jpg, jpeg, png, or gif.</p>
        </div>

        <div className=' mt-4'>
          <p className='mb-2 font-medium text-sm'>PDF Report Setting</p>
          {/* <Button
            block
            className='bg-[#7F56D9] text-white flex items-center justify-between'
            // onClick={() => window.open(url, '_blank')}
          >
            <span> Download PDF</span>
            <DownloadOutlined />
          </Button> */}
          <BlobProvider
            document={
              <MyDocument scenarios={scenarios} property={property} logoBase64={logoBase64} />
            }
          >
            {({ blob, url }) => {
              const handleClick = () => {
                if (!url) return
                const ele = document.createElement('a')
                ele.href = url
                ele.download = 'document.pdf'
                ele.click()
              }
              return (
                <button
                  className={`flex w-full items-center text-center justify-center border rounded-lg py-2 font-bold ${!url ? 'bg-[white] text-[black]' : 'bg-purple-600 text-white'}`}
                  icon={<DownloadOutlined />}
                  onClick={handleClick}
                  disabled={!url}
                >
                  Download PDF
                </button>
              )
            }}
          </BlobProvider>
          <p>You can only upload a jpg, jpeg, png, or gif.</p>
        </div>

        <div className='mt-4'>
          <p className='font-medium text-sm'>Share this report on </p>

          <div className='flex flex-row gap-4 mt-2 justify-between'>
            <FacebookShareButton
              url={'https://www.example.com'}
              quote={'Dummy text!'}
              hashtag='#muo'
            >
              <FacebookIcon />
            </FacebookShareButton>
            {/* <Button>
              <FacebookIcon />
            </Button> */}
            <Button>
              <YouTubeIcon />
            </Button>
            <LinkedinShareButton
              url={'https://www.example.com'}
              quote={'Dummy text!'}
              hashtag='#muo'
            >
              <LinkedIn />
            </LinkedinShareButton>
            {/* <Button>
              <LinkedIn />
            </Button> */}
            <Button>
              <Instagram />
            </Button>
            <Button>
              <Tiktok />
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PdfSharingModal
