import React from 'react'

function ClosedArrow() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='19' fill='none' viewBox='0 0 11 19'>
      <path
        stroke='#6941C6'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M1.406 17.207L9.695 9.29 1.406 1.372'
      ></path>
    </svg>
  )
}

export default ClosedArrow
