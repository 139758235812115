import React from 'react'

function YouTubeIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ display: 'inline-block', verticalAlign: 'middle' }}  // Added inline styles
    >
      <path
        d='M23.498 6.18613C23.4211 5.83769 23.2423 5.51717 22.9802 5.25798C22.7181 4.99879 22.3847 4.81219 22.0198 4.72183C20.2764 4.29783 12 4.29783 12 4.29783C12 4.29783 3.72356 4.29783 1.98023 4.72183C1.61532 4.81219 1.28187 4.99879 1.01981 5.25798C0.757743 5.51717 0.578911 5.83769 0.501954 6.18613C0.0857738 8.012 0 10.0006 0 12C0 13.9994 0.0857738 15.988 0.501954 17.8139C0.578911 18.1623 0.757743 18.4828 1.01981 18.742C1.28187 19.0012 1.61532 19.1878 1.98023 19.2782C3.72356 19.7022 12 19.7022 12 19.7022C12 19.7022 20.2764 19.7022 22.0198 19.2782C22.3847 19.1878 22.7181 19.0012 22.9802 18.742C23.2423 18.4828 23.4211 18.1623 23.498 17.8139C23.9142 15.988 24 13.9994 24 12C24 10.0006 23.9142 8.012 23.498 6.18613ZM9.60241 15.5457V8.4543L15.5457 12L9.60241 15.5457Z'
        fill='#2F2B43'
        fillOpacity='0.6'
      />
    </svg>
  )
}

export default YouTubeIcon
