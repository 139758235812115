import Footer from './Footer'
import Header from './Header'
import './styles.scss'
const Layout = ({ children }) => {
  return (
    <div className='layout radial-gradient-top w-full'>
      <div className='flex flex-col w-full'>
        <Header />
        <div className='lg:pt-[70px] pt-[35px] w-5/6 mx-auto layout-height'>{children}</div>
        <Footer />
      </div>
    </div>
  )
}
export default Layout
