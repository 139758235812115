import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import PageLoader from '../../components/PageLoader'

function Thankyoupage() {
  const location = useLocation()
  const navigate = useNavigate()
  const [email, setEmail] = useState(null)
  const [id, setId] = useState(null)
  // useEffect(() => {
  //   console.log('state', location.state)
  //   if (location?.state?.id && location?.state?.email) {
  //     setId(location.state.id)
  //     setEmail(location.state.email)
  //     window.history.replaceState({}, '')
  //   } else {
  //     navigate('/leadgen')
  //   }
  // }, [location])
  const navigateToProperty = () => {
    if (email && id) {
      navigate('/property', {
        state: {
          email,
          id,
        },
      })
    } else {
      navigate('/leadgen')
    }
  }
  const [pageData, setPageData] = useState({})
  const [loading, setLoading] = useState(false)
  const getPageData = async () => {
    setLoading(true)
    try {
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/thankyous/1?populate=*`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.id && res?.data?.data?.attributes?.publishedAt != null) {
        setPageData(res.data.data.attributes)
      }
    } catch (error) {
      console.log('error in fetch blogs', error)
    }
    setLoading(false)
    return true
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    getPageData()
  }, [])
  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <div className='flex flex-col justify-center items-center'>
          <div className='text-center px-4 md:px-0 sm:px-2 mt-0 lg:mt-28'>
            <h1 className='text-3xl lg:text-5xl font-semibold mb-4'>{pageData?.title}</h1>

            <p className='text-1xl lg:text-xl sm:text-2xl text-gray-600'>{pageData?.description}</p>
          </div>
          {(pageData?.image?.data?.attributes?.url ||
            pageData?.image?.data?.attributes?.alternativeText) && (
            <div className='my-8'>
              <img
                src={pageData.image.data.attributes.url}
                alt={pageData.image.data.attributes.alternativeText ?? 'Image'}
                className='max-w-full h-auto'
              />
            </div>
          )}
          {pageData?.accessCalculator?.id && (
            <div className='bg-gray-200 py-10 w-full rounded-lg text-center mx-4 md:mx-auto mb-20'>
              <h2 className='text-3xl font-medium mb-4 font-inter'>
                {pageData?.accessCalculator?.title}
              </h2>
              <p className='font-inter'>{pageData?.accessCalculator?.title}</p>
              <Button
                className='bg-[#110101] text-white py-2 px-6 rounded-md shadow-lg mt-6 flex justify-center items-center mx-auto'
                disabled={!id || !email}
                onClick={navigateToProperty}
              >
                {pageData?.accessCalculator?.btnText}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Thankyoupage
