import { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'antd';
import './styles.scss';
import Logo from '../../../assets/svg/Logo';
import { Link, useNavigate } from 'react-router-dom';
import FacebookIcon from '../../../assets/svg/FacebookIcon';
import YouTubeIcon from '../../../assets/svg/YouTubeIcon';
import LinkedIn from '../../../assets/svg/LinkedIn';
import { useAuth } from '../../../hooks/useAppContext';

const Footer = () => {
  const { isLoggedIn } = useAuth();
  const [footerData, setFooterData] = useState(null);
  const navigate = useNavigate();
  
  // Fetch data from Strapi API
  const getFooterData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/footers/1?populate=*`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      });
      setFooterData(res.data.data.attributes);

    } catch (error) {
      console.log('Error fetching footer data:', error);
    }
  };
  
  const footerData1 = {
    links_1: [
      { id: 34, title: 'Fix and Flip', path: '/property' },
      { id: 35, title: 'Performing Note', path: '/performingNote' }
    ]
  };
  

  useEffect(() => {
    getFooterData();
  }, []);

  // Render fallback content if footerData is not yet loaded
  if (!footerData) return <div>Loading...</div>;

  return (
    <div className='footer radial-gradient-top'>
      <div className='w-5/6 py-3 mx-auto'>
        <div className='mt-10 sm:mt-20 lg:mt-28'>
          <div className='grid grid-cols-1 gap-4 lg:grid-cols-2'>
            <div>
              <h1 className='text-lg lg:text-xl font-semibold leading-tight text-[#2F2B43]'>
                {footerData.title}
              </h1>
              <p className='mt-3 font-normal text-base leading-normal text-[#2F2B4399]'>
                {footerData.description}
              </p>
            </div>
            <div className='flex justify-end'>
              <Button
                onClick={() => {
                  // if (footerData.bookACall.targetBlank) {
                  //   window.open(footerData.bookACall.path, '_blank');
                  // } else {
                  //   navigate(footerData.bookACall.path);
                  // }
                  navigate('/book-a-call');
                }}
                type='primary'
                className='w-full flex justify-center items-center h-10 px-3 lg:px-4 lg:w-auto text-white bg-[#7F56D9] font-medium text-base'
              >
                {footerData.bookACall.btnText}
              </Button>
            </div>
          </div>
          <div className='grid grid-cols-1 gap-4 mt-10 lg:grid-cols-2 lg:mt-24'>
            <div>
              <div className='flex items-end'>
                <span
                  className='flex items-end cursor-pointer'
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  <span className='mb-1 mr-2'>
                    <Logo />
                  </span>
                  <span className='font-bold text-lg lg:text-xl text-[#1D2939]'>
                    Blu Hat Bookkeeping
                  </span>
                </span>
              </div>
            </div>
            <div className='pr-4 lg:pr-10'>
              <div className='grid grid-cols-1 gap-4 mb-5 sm:grid-cols-2 lg:grid-cols-3'>
                {footerData.links_1_heading && (
                  <div className='flex flex-col'>
                  <h1 className='font-medium text-[16px] leading-[-1%] text-[#2F2B4399]'>
                    {footerData?.links_1_heading}
                  </h1>
                  {
                    footerData?.links_1?.map((link) => (
                      
                      <Link
                        to={link.path}
                        key={`link1-${link.id}`}
                        className='font-medium text-[16px] text-[#000000] mt-[5px]'
                      >
                        {link.title}
                      </Link>
                    ))}
                </div>                
                )}
                {footerData.link_2_heading && (
                  <div className='flex flex-col'>
                    <h1 className='font-medium text-[16px] leading-[-1%] text-[#2F2B4399]'>
                      {footerData.link_2_heading}
                    </h1>
                    {footerData.links_2.map((link) => (
                      <Link
                        to={link.path}
                        key={`link2-${link.id}`}
                        className='font-medium text-[16px] text-[#000000] mt-[5px]'
                      >
                        {link.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className='bg-gray-200 text-grey'>
        <div className='w-5/6 py-4 mx-auto'>
          <div className='container flex flex-col items-start justify-between mx-auto sm:flex-row sm:items-center'>
            <div className='flex flex-row flex-wrap items-center mb-4 text-gray-600 sm:mb-0'>
              <p className='text-xs sm:text-sm whitespace-nowrap'>Blu Hat Bookkeeping</p>
              <p className='text-xs sm:text-sm ml- sm:ml-4 whitespace-nowrap'>
                All rights reserved. © {new Date().getFullYear()}
              </p>
            </div>
            <div className='flex space-x-4'>
              <Link to='https://www.facebook.com/profile.php?id=61564529010630' aria-label='Facebook'>
                <p>
                  <FacebookIcon />
                </p>
              </Link>
              <Link to='https://www.youtube.com/@bluhatbookkeeping' aria-label='YouTube'>
                <p>
                  <YouTubeIcon />
                </p>
              </Link>
              <Link to='https://www.linkedin.com/company/bluhatbookkeeping/' aria-label='LinkedIn'>
                <p>
                  <LinkedIn />
                </p>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
