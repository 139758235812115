import { Page, Text, View, Image, Document, StyleSheet, Link } from '@react-pdf/renderer'
import { BlobProvider } from '@react-pdf/renderer'
import { useEffect, useState } from 'react'
import { Modal, Table } from 'antd'
import HeroSection from '../../components/HeroSection'
import ScrollingCardPerforming from '../../components/ScrollingCardPerforming'
import CreatedPerformingNotesForm from '../../pages/CreatedPerformingNotes'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import './styles.scss'
import { showErrorNotification } from '../../notifications/Error/notification'
import PerformingNoteSharingModal from '../../components/PerformingNoteSharingModal'
import PageLoader from '../../components/PageLoader'
const CreatedPerformingNotes = () => {
  const params = useParams()
  const [initialLoading, setInitialLoading] = useState(false)
  const [pageData, setPageData] = useState(false)
  const [openNoteSharingModal, setOpenNoteSharingModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [calculations, setCalculations] = useState({})
  const [property, setProperty] = useState({})
  const [scenarios, setScenarios] = useState([])
  const [activeScenarioId, setActiveScenarioId] = useState(0)
  const [triggerActiveScenario, setTriggerActiveScenario] = useState(false)
  const [activeScenarioProvidedValue, setActiveScenarioProvidedValue] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [amortization, setAmortization] = useState([])
  const [totalCalculations, setTotalCalculations] = useState({
    principal: 0,
    interest: 0,
    paid: 0,
  })
  const [chunks, setChunks] = useState([])
  const chunkArray = (arr, size) => {
    let result = []
    if (arr.length > 0) {
      result.push(arr.slice(0, 30))
    }
    for (let i = 30; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size))
    }
    return result
  }
  const numberFormator = (value) => {
    try {
      const numericValue = value?.toString()?.replace(/[^0-9.]/g, '')
      return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } catch (error) {
      console.log(error)
    }
  }
  const fixDecimals = (value) => {
    return value.toFixed(2)
  }
  const handleOk = () => {
    console.log('ok clicked')
    Modal.destroyAll()
    setAmortization([])
    setChunks([])
    setOpenModal(false)
    setTotalCalculations({})
  }
  const handleCancel = () => {
    Modal.destroyAll()
    console.log('cancel clicked')
    setAmortization([])
    setChunks([])
    setOpenModal(false)
    setTotalCalculations({})
  }
  const columns = [
    {
      title: <div style={{ width: '100%', textAlign: 'center' }}>Payment No.</div>,
      key: 'sno',
      width: '14%',
      className: 'text-center',
      render: (text, object, index) => {
        return index + 1
      },
    },
    {
      title: <div style={{ width: '100%', textAlign: 'center' }}>Interest</div>,
      dataIndex: 'interest',
      width: '28%',
      key: 'interest',
      className: 'text-center',
      render: (text) => {
        return (
          <span>
            {text?.toString() ? <>$ {numberFormator(parseFloat(text).toFixed(2))}</> : '$ 0.00'}
          </span>
        )
      },
    },
    {
      title: <div style={{ width: '100%', textAlign: 'center' }}>P&I</div>,
      dataIndex: 'pAndI',
      key: 'pAndI',
      width: '28%',
      className: 'text-center',
      render: (text) => {
        return (
          <span>
            {text?.toString() ? <>$ {numberFormator(parseFloat(text).toFixed(2))}</> : '$ 0.00'}
          </span>
        )
      },
    },
    {
      title: <div style={{ width: '100%', textAlign: 'center' }}>Closing Balance</div>,
      dataIndex: 'closingBalance',
      key: 'closingBalance',
      width: '30%',
      className: 'text-center',
      render: (text) => {
        return (
          <span>
            {text?.toString() ? <>$ {numberFormator(parseFloat(text).toFixed(2))}</> : '$ 0.00'}
          </span>
        )
      },
    },
  ]

  const makeAmortizationCalculations = (tempCalculations, outcomeType) => {
    if (!calculations?.monthsLeft && tempCalculations?.monthsLeft) {
      setCalculations({ ...tempCalculations })
    }
    // 7- START Amortization Schedule for Outcome 1
    let amortPrice, amortYield
    if (outcomeType === 'price') {
      amortPrice = tempCalculations.price_3
      amortYield = tempCalculations.yield_3
    } else if (outcomeType === 'yield') {
      amortPrice = tempCalculations.price_2
      amortYield = tempCalculations.yield_2
    } else if (outcomeType === 'upb') {
      amortPrice = tempCalculations.price_1
      amortYield = tempCalculations.yield_1
    }

    //price_2, yield_2, dueDiligenceCost, pmt,
    const schedule = []
    if (
      amortPrice?.toString() &&
      amortYield?.toString() &&
      tempCalculations?.dueDiligenceCost?.toString() &&
      tempCalculations?.pmt?.toString() &&
      tempCalculations?.monthlyServicingCost?.toString() &&
      tempCalculations?.monthsLeft?.toString()
    ) {
      setTotalCalculations({})
      try {
        let openingBalance = 0,
          totalPaid = 0,
          totalInterest = 0,
          principal = 0,
          interest = 0,
          pAndI = 0,
          closingBalance = 0,
          tempYield = 0,
          i = 0
        openingBalance = amortPrice + tempCalculations.dueDiligenceCost
        principal = openingBalance
        pAndI = tempCalculations.pmt - tempCalculations.monthlyServicingCost
        tempYield = amortYield / 100 / 12

        do {
          i = i + 1
          interest = openingBalance * tempYield
          closingBalance = openingBalance + interest - pAndI
          if (i == tempCalculations?.monthsLeft) {
            if (closingBalance !== 0) {
              pAndI = pAndI + closingBalance
              closingBalance = 0
            }
          }
          schedule.push({ openingBalance, interest, pAndI, closingBalance })
          openingBalance = closingBalance
          totalInterest = interest + totalInterest
          totalPaid = pAndI + totalPaid
        } while (closingBalance > 1)
        setTotalCalculations({
          principal: principal,
          interest: totalInterest,
          paid: totalPaid,
        })
      } catch (error) {
        console.log('error in amortization schedule', error)
      }
    }
    const tempChunks = chunkArray(schedule, 50)
    setChunks(tempChunks)
    setAmortization(schedule)
    setOpenModal(true)
    // 7- END Amortization Schedule for Outcome 1
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: 'transparent',
    },
    backgroundImage: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    main: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      padding: 40,
      fontSize: 12,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      border: '1px solid black',
    },
    mainHeading: {
      fontSize: 18,
      fontWeight: 'bold',
    },

    heading: {
      fontSize: 14,
      fontWeight: 'bold',
    },

    text: {
      fontSize: 12,
      color: '#475467',
    },
    logoBar: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    defaultLogo: {
      display: 'flex',
      flexDirection: 'row',
    },
    logo: {
      width: 40,
      height: 30,
      border: '1px solid black',
    },
    informationLine: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: 4,
    },
    propertyInformation: {
      display: 'flex',
      flexDirection: 'row',
      width: '49%',
      color: '#475467',
    },
    informationHeading: {
      textAlign: 'right',
    },
    info: {
      paddingLeft: '6px',
    },
    info1: {
      paddingLeft: '9px',
    },
    footer: {
      marginTop: 'auto',
      borderTop: '1px solid black',
      paddingTop: 5,
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 5,
    },
    footertxt: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    footerlnk: {
      fontSize: 12,
      fontWeight: 'bold',
    },
  })

  const MyDocument = ({ chunks, calculations, totalCalculations }) => (
    <Document>
      {chunks.map((arr, index) => {
        if (index == 0) {
          return (
            <Page size='A4' style={styles.page}>
              <Image src='/pdfBg.jpg' style={styles.backgroundImage} />
              <View style={styles.main}>
                <View>
                  <View style={[styles.logoBar, { alignItems: 'baseline', width: '30%' }]}>
                    <View style={styles.defaultLogo}>
                      <Image src='/pdfLogo2.jpg' style={styles.logo} />
                    </View>
                  </View>
                  {/* <View
                    style={[
                      styles.logoBar,
                      styles.mainHeading,
                      { justifyContent: 'center', paddingTop: 30, width: '40%' },
                    ]}
                  >
                    <Text style={{}}>Performing Scenario</Text>
                  </View> */}
                  <View
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'column',
                      marginTop: 16,
                    }}
                  >
                    <Text style={styles.heading}>Property’s Address</Text>
                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                      <Text style={[styles.text, { minWidth: '90px' }]}>Street Address:</Text>
                      <Text style={styles.text}>{calculations?.address ?? ''}</Text>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 4,
                        marginBottom: 16,
                        width: '100%',
                      }}
                    >
                      <View style={[styles.text, { display: 'flex', flexDirection: 'row' }]}>
                        <Text style={[styles.text, { minWidth: '30px' }]}>City:</Text>
                        <Text>{calculations?.city ?? ''}</Text>
                      </View>
                      <View
                        style={[
                          styles.text,
                          {
                            display: 'flex',
                            flexDirection: 'row',
                            marginLeft: 40,
                            marginRight: 40,
                          },
                        ]}
                      >
                        <Text style={[styles.text, { minWidth: '40px' }]}>State:</Text>
                        <Text>{calculations?.state ?? ''}</Text>
                      </View>
                      <View style={[styles.text, { display: 'flex', flexDirection: 'row' }]}>
                        <Text style={[styles.text, { minWidth: '30px' }]}>Zip:</Text>
                        <Text>{calculations?.zipcode ?? ''}</Text>
                      </View>
                    </View>
                  </View>
                  {/* property information */}
                  <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text style={styles.heading}>Original Note</Text>
                    <View>
                      <View style={styles.informationLine}>
                        <View style={styles.propertyInformation}>
                          <Text style={styles.informationHeading}>(N) Months Left:</Text>
                          {calculations?.monthsLeft?.toString() ? (
                            <Text style={styles.info}> {calculations?.monthsLeft}</Text>
                          ) : (
                            <Text style={styles.info}>N/A</Text>
                          )}
                        </View>
                        <View style={styles.propertyInformation}>
                          <Text style={[{ paddingRight: 4 }, styles.informationHeading]}>
                            (UPB) Loan Amount:
                          </Text>
                          {calculations?.upb ? (
                            <Text style={styles.info}>
                              {' '}
                              ${numberFormator(calculations.upb.toFixed(2))}
                            </Text>
                          ) : (
                            <Text style={styles.info}>N/A</Text>
                          )}
                        </View>
                      </View>
                      <View style={styles.informationLine}>
                        <View style={styles.propertyInformation}>
                          <Text style={styles.informationHeading}>I/YR:</Text>
                          {calculations?.ir ? (
                            <Text style={styles.info}>
                              {' '}
                              ${numberFormator(calculations.ir.toFixed(2))}
                            </Text>
                          ) : (
                            <Text style={styles.info}>N/A</Text>
                          )}
                        </View>
                        <View style={styles.propertyInformation}>
                          <Text style={styles.informationHeading}>PMT (Monthly Payment):</Text>
                          {calculations?.pmt ? (
                            <Text style={styles.info}>
                              {' '}
                              ${numberFormator(calculations.pmt.toFixed(2))}
                            </Text>
                          ) : (
                            <Text style={styles.info}>N/A</Text>
                          )}
                        </View>
                      </View>
                      <View style={styles.informationLine}>
                        <View style={styles.propertyInformation}>
                          <Text style={styles.informationHeading}>Due Diligence Cost:</Text>
                          {calculations?.dueDiligenceCost ? (
                            <Text style={styles.info}>
                              {' '}
                              ${numberFormator(calculations.dueDiligenceCost.toFixed(2))}
                            </Text>
                          ) : (
                            <Text style={styles.info}>$0.00</Text>
                          )}
                        </View>
                        <View style={styles.propertyInformation}>
                          <Text style={styles.informationHeading}>Monthly Servicing Cost:</Text>
                          {calculations?.monthlyServicingCost ? (
                            <Text style={styles.info}>
                              {' '}
                              ${numberFormator(calculations.monthlyServicingCost.toFixed(2))}
                            </Text>
                          ) : (
                            <Text style={styles.info}>$0.00</Text>
                          )}
                        </View>
                      </View>
                      <Text style={[styles.heading, { margin: '15px 0 2px 0' }]}>
                        Note Amortization
                      </Text>
                      <View style={styles.informationLine}>
                        <View style={styles.propertyInformation}>
                          <Text style={styles.informationHeading}>Total Interest:</Text>
                          {totalCalculations?.interest ? (
                            <Text style={styles.info}>
                              {' '}
                              ${numberFormator(totalCalculations.interest.toFixed(2))}
                            </Text>
                          ) : (
                            <Text style={styles.info}>$0.00</Text>
                          )}
                        </View>
                        <View style={styles.propertyInformation}>
                          <Text style={styles.informationHeading}>Total Principle:</Text>

                          {totalCalculations?.principal ? (
                            <Text style={styles.info1}>
                              {' '}
                              ${numberFormator(totalCalculations.principal.toFixed(2))}
                            </Text>
                          ) : (
                            <Text style={styles.info1}>$0.00</Text>
                          )}
                        </View>
                      </View>
                      <View style={styles.informationLine}>
                        <View style={styles.propertyInformation}>
                          <Text style={styles.informationHeading}>Amortization Payments:</Text>
                          {calculations?.monthsLeft ? (
                            <Text style={styles.info}>1 to {calculations.monthsLeft}</Text>
                          ) : (
                            <Text style={styles.info}>N/A</Text>
                          )}
                        </View>
                        <View style={styles.propertyInformation}>
                          <Text style={styles.informationHeading}>Total Paid:</Text>
                          {totalCalculations?.paid ? (
                            <Text style={styles.info1}>
                              {' '}
                              ${numberFormator(totalCalculations.paid.toFixed(2))}
                            </Text>
                          ) : (
                            <Text style={styles.info1}>$0.00</Text>
                          )}
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginTop: '30px',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      flexWrap: 'wrap',
                      borderBottom: '1px solid black',
                    }}
                  >
                    <Text
                      style={{ borderRight: '1px solid black', width: '21%', textAlign: 'center' }}
                    >
                      Payment #
                    </Text>
                    <Text
                      style={{ borderRight: '1px solid black', width: '25%', textAlign: 'center' }}
                    >
                      Interest
                    </Text>
                    <Text
                      style={{ borderRight: '1px solid black', width: '25%', textAlign: 'center' }}
                    >
                      P & I
                    </Text>
                    <Text style={{ width: '26%', textAlign: 'center' }}>Closing Balance</Text>
                  </View>
                  {arr.map((item, i) => {
                    return (
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          flexWrap: 'wrap',
                          borderBottom: '1px solid black',
                          width: '100%',
                        }}
                      >
                        <Text
                          style={{
                            borderRight: '1px solid black',
                            width: '21%',
                            textAlign: 'center',
                          }}
                        >
                          {i + 1}
                        </Text>

                        <Text
                          style={{
                            borderRight: '1px solid black',
                            width: '25%',
                            textAlign: 'center',
                          }}
                        >
                          {numberFormator(item.interest.toFixed(2))}
                        </Text>
                        <Text
                          style={{
                            borderRight: '1px solid black',
                            width: '25%',
                            textAlign: 'center',
                          }}
                        >
                          {numberFormator(item.pAndI.toFixed(2))}
                        </Text>
                        <Text style={{ width: '26%', textAlign: 'center' }}>
                          {numberFormator(item.closingBalance.toFixed(2))}
                        </Text>
                      </View>
                    )
                  })}
                </View>
              </View>
              <View style={styles.footer}>
                <Text style={styles.footertxt}>
                  Create your free bookkeeping scenario at{' '}
                  <Link src='https://bluhatbookkepping.com/' style={styles.footerlnk}>
                    BluHatBookKeeping.com
                  </Link>{' '}
                  or book a free call to simplify your finances now!
                </Text>
              </View>
            </Page>
          )
        } else {
          return (
            <Page size='A4' style={styles.page}>
              <Image src='/pdfBg.jpg' style={styles.backgroundImage} />
              <View style={styles.main}>
                <View style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      flexWrap: 'wrap',
                      borderBottom: '1px solid black',
                      width: '100%',
                    }}
                  >
                    <Text
                      style={{ borderRight: '1px solid black', width: '21%', textAlign: 'center' }}
                    >
                      Payment #
                    </Text>

                    <Text
                      style={{ borderRight: '1px solid black', width: '25%', textAlign: 'center' }}
                    >
                      Interest
                    </Text>
                    <Text
                      style={{ borderRight: '1px solid black', width: '25%', textAlign: 'center' }}
                    >
                      P & I
                    </Text>
                    <Text style={{ width: '26%', textAlign: 'center' }}>Closing Balance</Text>
                  </View>
                  {arr.map((item, i) => {
                    return (
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          flexWrap: 'wrap',
                          borderBottom: '1px solid black',
                          width: '100%',
                        }}
                      >
                        <Text
                          style={{
                            borderRight: '1px solid black',
                            width: '21%',
                            textAlign: 'center',
                          }}
                        >
                          {index * 50 - 20 + i + 1}
                        </Text>
                        <Text
                          style={{
                            borderRight: '1px solid black',
                            width: '25%',
                            textAlign: 'center',
                          }}
                        >
                          {item.interest.toFixed(2)}
                        </Text>
                        <Text
                          style={{
                            borderRight: '1px solid black',
                            width: '25%',
                            textAlign: 'center',
                          }}
                        >
                          {item.pAndI.toFixed(2)}
                        </Text>
                        <Text style={{ width: '26%', textAlign: 'center' }}>
                          {item.closingBalance.toFixed(2)}
                        </Text>
                      </View>
                    )
                  })}
                </View>
              </View>
              <View style={styles.footer}>
                <Text style={styles.footertxt}>
                  Create your free bookkeeping scenario at{' '}
                  <Link src='https://bluhatbookkepping.com/' style={styles.footerlnk}>
                    BluHatBookKeeping.com
                  </Link>{' '}
                  or book a free call to simplify your finances now!
                </Text>
              </View>
            </Page>
          )
        }
      })}
    </Document>
  )
  const getInitialData = async (id) => {
    setLoading(true)
    try {
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_URL}/performanceProperty/${id}`,
      }

      const res = await axios.request(config)
      if (res?.status == 200 && res?.data?.data?.id) {
        const tempProperty = res.data.data
        if (tempProperty?.upb?.toString()) {
          tempProperty.upb = parseFloat(tempProperty.upb)
        }
        if (tempProperty?.ir?.toString()) {
          tempProperty.ir = parseFloat(tempProperty.ir)
        }
        if (tempProperty?.pmt?.toString()) {
          tempProperty.pmt = parseFloat(tempProperty.pmt)
        }
        if (tempProperty?.dueDiligenceCost?.toString()) {
          tempProperty.dueDiligenceCost = parseFloat(tempProperty.dueDiligenceCost)
        }
        if (tempProperty?.monthlyServicingCost?.toString()) {
          tempProperty.monthlyServicingCost = parseFloat(tempProperty.monthlyServicingCost)
        }
        setProperty(tempProperty)
        try {
          let scenarioConfig = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/performanceScenario/property/${id}`,
          }

          const scenarioRes = await axios.request(scenarioConfig)
          if (scenarioRes?.status == 200 && scenarioRes?.data?.length > 0) {
            const tempScenario = scenarioRes.data.map((item) => {
              if (item?.price_1?.toString()) {
                item.price_1 = parseFloat(item.price_1)
              }
              if (item?.price_2?.toString()) {
                item.price_2 = parseFloat(item.price_2)
              }
              if (item?.price_3?.toString()) {
                item.price_3 = parseFloat(item.price_3)
              }
              if (item?.upb_1?.toString()) {
                item.upb_1 = parseFloat(item.upb_1)
              }
              if (item?.upb_2?.toString()) {
                item.upb_2 = parseFloat(item.upb_2)
              }
              if (item?.upb_3?.toString()) {
                item.upb_3 = parseFloat(item.upb_3)
              }
              if (item?.yield_1?.toString()) {
                item.yield_1 = parseFloat(item.yield_1)
              }
              if (item?.yield_2?.toString()) {
                item.yield_2 = parseFloat(item.yield_2)
              }
              if (item?.yield_3?.toString()) {
                item.yield_3 = parseFloat(item.yield_3)
              }
              return item
            })
            if (tempScenario?.length >= 3) {
              setScenarios(tempScenario)
              setActiveScenarioId(scenarioRes.data[0].id)
            } else {
              setScenarios([...tempScenario, {}])
            }
          } else {
            setScenarios([{}])
          }
        } catch (error) {
          showErrorNotification('Error', 'Scenario not found')
        }
      } else {
        showErrorNotification('Error', 'Property not found')
      }
    } catch (e) {
      showErrorNotification('Error', 'Property not found')
    }
    setLoading(false)
    return true
  }
  const getPageData = async () => {
    try {
      setInitialLoading(true)
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/performing-notes/1?populate=*`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.id && res?.data?.data?.attributes?.publishedAt != null) {
        console.log('done it ')
        setPageData(res.data.data.attributes)
      }
    } catch (error) {
      console.log('error')
    }

    setInitialLoading(false)
    return true
  }

  useEffect(() => {
    const getData = async () => {
      await getPageData()
      await getInitialData(params?.id)
    }
    if (params?.id) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      getData()
      // getPageData()
      // getInitialData(params?.id)
    }
  }, [params])
  useEffect(() => {
    if (!calculations?.monthsLeft && property?.monthsLeft) {
      setCalculations({ ...property })
    }
  }, [calculations, property])
  return (
    <div>
      {initialLoading ? (
        <PageLoader />
      ) : (
        <div className='relative createdPerTable'>
          <HeroSection data={pageData} />
          <CreatedPerformingNotesForm
            loading={loading}
            setLoading={setLoading}
            property={property}
            setProperty={setProperty}
            scenarios={scenarios}
            setScenarios={setScenarios}
            calculations={calculations}
            setCalculations={setCalculations}
            activeScenarioId={activeScenarioId}
            setActiveScenarioId={setActiveScenarioId}
            triggerActiveScenario={triggerActiveScenario}
            setTriggerActiveScenario={setTriggerActiveScenario}
            activeScenarioProvidedValue={activeScenarioProvidedValue}
            setActiveScenarioProvidedValue={setActiveScenarioProvidedValue}
            totalCalculations={totalCalculations}
            setTotalCalculations={setTotalCalculations}
            makeAmortizationCalculations={makeAmortizationCalculations}
          />
          <ScrollingCardPerforming
            property={property}
            values={calculations}
            scenarios={scenarios}
            activeScenarioId={activeScenarioId}
            pageType=''
            makeAmortizationCalculations={makeAmortizationCalculations}
            loading={loading}
            numberFormator={numberFormator}
            fixDecimals={fixDecimals}
            openNoteSharingModal={openNoteSharingModal}
            setOpenNoteSharingModal={setOpenNoteSharingModal}
          />
          <PerformingNoteSharingModal
            open={openNoteSharingModal}
            setOpen={setOpenNoteSharingModal}
            scenarios={scenarios}
            property={property}
            numberFormator={numberFormator}
          />
          <div className='your-table'>
            <Modal
              title='Amortization Schedule'
              open={openModal}
              onOk={handleOk}
              onCancel={handleCancel}
              width='1000px'
            >
              <div>
                <div
                  className='w-full flex justify-center text-[18px] font-semibold'
                  style={{ borderBottom: '1px solid black' }}
                >
                  {calculations?.address && <span>{calculations.address}</span>}
                  {calculations?.city && (
                    <span>
                      <span className='mr-2'>,</span>
                      {calculations.city}
                    </span>
                  )}
                  {calculations?.state && (
                    <span>
                      <span className='mr-2'>,</span>
                      {calculations.state}
                    </span>
                  )}
                  {calculations?.zipcode && (
                    <span>
                      <span className='mr-2'>,</span>
                      {calculations.zipcode}
                    </span>
                  )}
                </div>
                <div className='w-full flex flex-col md:flex-row jusitfy-between'>
                  <div className='w-full md:w-1/2 flex flex-col'>
                    <div className='flex w-full justify-start mt-2'>
                      <div className='w-1/2 font-semibold text-[16px]'>(N) Months:</div>
                      <div className='w-1/2 font-normal text-[16px] text-[#667085]'>
                        {calculations?.monthsLeft?.toString() && (
                          <>{numberFormator(calculations.monthsLeft)}</>
                        )}
                      </div>
                    </div>
                    <div className='flex w-full justify-start mt-1'>
                      <div className='w-1/2 font-semibold text-[16px]'>I/YR:</div>
                      <div className='w-1/2 font-normal text-[16px] text-[#667085]'>
                        {calculations?.ir?.toString() && (
                          <>{numberFormator(fixDecimals(calculations.ir))} %</>
                        )}
                      </div>
                    </div>
                    <div className='flex w-full justify-start mt-1'>
                      <div className='w-1/2 font-semibold text-[16px]'>Due Diligence Cost:</div>
                      <div className='w-1/2 font-normal text-[16px] text-[#667085]'>
                        {calculations?.dueDiligenceCost?.toString() ? (
                          <>$ {numberFormator(fixDecimals(calculations.dueDiligenceCost))}</>
                        ) : (
                          <>$ 0.00</>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 flex flex-col'>
                    <div className='flex w-full justify-start mt-2'>
                      <div className='w-1/2 font-semibold text-[16px]'>UPB (Loan Amount Left):</div>
                      <div className='w-1/2 font-normal text-[16px] text-[#667085]'>
                        {calculations?.upb?.toString() && (
                          <>$ {numberFormator(fixDecimals(calculations.upb))}</>
                        )}
                      </div>
                    </div>
                    <div className='flex w-full justify-start mt-1'>
                      <div className='w-1/2 font-semibold text-[16px]'>PMT (Monthly Payment):</div>
                      <div className='w-1/2 font-normal text-[16px] text-[#667085]'>
                        {calculations?.pmt?.toString() && (
                          <>$ {numberFormator(fixDecimals(calculations.pmt))}</>
                        )}
                      </div>
                    </div>
                    <div className='flex w-full justify-start mt-1'>
                      <div className='w-1/2 font-semibold text-[16px]'>Monthly Servicing Cost:</div>
                      <div className='w-1/2 font-normal text-[16px] text-[#667085]'>
                        {calculations?.monthlyServicingCost?.toString() ? (
                          <>$ {numberFormator(fixDecimals(calculations.monthlyServicingCost))}</>
                        ) : (
                          <>$ 0.00</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full flex-col md:flex-row flex justify-between mt-4'>
                  <div className=''>
                    <span className='font-semibold text-[16px]'>Total Principle:</span>
                    <span className='font-semibold text-[16px]'>
                      {totalCalculations?.principal ? (
                        <>$ {numberFormator(fixDecimals(totalCalculations.principal))}</>
                      ) : (
                        <>$ 0.00</>
                      )}
                    </span>
                  </div>
                  <div className=''>
                    <span className='font-semibold text-[16px]'>Total Interest:</span>
                    <span className='font-semibold text-[16px] '>
                      {totalCalculations?.interest ? (
                        <>$ {numberFormator(fixDecimals(totalCalculations.interest))}</>
                      ) : (
                        <>$ 0.00</>
                      )}
                    </span>
                  </div>
                  <div className=''>
                    <span className='font-semibold text-[16px]'>Total Paid:</span>
                    <span className='font-semibold text-[16px]'>
                      {totalCalculations?.paid ? (
                        <>$ {numberFormator(fixDecimals(totalCalculations.paid))}</>
                      ) : (
                        <>$ 0.00</>
                      )}
                    </span>
                  </div>
                </div>
                <div className='flex flex-col md:flex-row justify-between items-center md:items-end mt-4 mb-2'>
                  <h1 className='font-semibold text-[16px]'>
                    Amortization Payments:1 to{' '}
                    {calculations?.monthsLeft?.toString() ? (
                      <> {numberFormator(calculations.monthsLeft)}</>
                    ) : (
                      'N/A'
                    )}{' '}
                    months
                  </h1>
                  <div className='w-full md:w-[30%] mt-4 mb-2'>
                    <BlobProvider
                      document={
                        <MyDocument
                          chunks={chunks}
                          calculations={calculations}
                          totalCalculations={totalCalculations}
                        />
                      }
                    >
                      {({ blob, url }) => {
                        const handleClick = () => {
                          if (!url) return
                          const ele = document.createElement('a')
                          ele.href = url
                          ele.download = 'document.pdf'
                          ele.click()
                        }
                        return (
                          <button
                            className={`flex w-full items-center text-center justify-center border rounded-lg py-2 font-bold ${!url ? 'bg-[white] text-[black]' : 'bg-purple-600 text-white'}`}
                            // icon={<DownloadOutlined />}
                            onClick={handleClick}
                            disabled={!url}
                          >
                            Download PDF
                          </button>
                        )
                      }}
                    </BlobProvider>
                  </div>
                </div>
                <Table
                  scroll={{ x: 400 }}
                  dataSource={amortization}
                  columns={columns}
                  pagination={false}
                />
              </div>
            </Modal>
          </div>
        </div>
      )}
    </div>
  )
}
export default CreatedPerformingNotes
