import usStates from './usStates'
import { rate, pv } from 'financial'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'sunflower-antd'
import { Button, Form, InputNumber, AutoComplete, Input } from 'antd'
import InputField from '../InputField'
import './styles.scss'
import axios from 'axios'
import { showSuccessNotification } from '../../notifications/Success/notification'
import { showErrorNotification } from '../../notifications/Error/notification'
import { useNavigate } from 'react-router-dom'
const PerformingNotesForm = ({
  loading,
  setLoading,
  calculations,
  setCalculations,
  property,
  setProperty,
  scenario,
  setScenario,
  totalCalculations,
  setTotalCalculations,
  makeAmortizationCalculations,
}) => {
  const navigate = useNavigate()
  const [upbEditable, setUpbEditable] = useState(false)
  const [yieldEditable, setYieldEditable] = useState(false)
  const [priceEditable, setPriceEditable] = useState(false)
  const upbRef = useRef(null)
  const yieldRef = useRef(null)
  const priceRef = useRef(null)
  const [form] = Form.useForm()
  const [disableRunScenario, setDisableRunScenario] = useState(true)
  const { formProps } = useForm({
    form,
    async submit(formValues) {
      console.log('submit', formValues)
      await new Promise((r) => setTimeout(r, 1000))
      return 'ok'
    },
  })
  const [inputValue, setInputValue] = useState('')
  const [validationTrigger, setValidationTrigger] = useState({
    property: {
      address: ['onBlur'],
      city: ['onBlur'],
      state: ['onBlur'],
      zipcode: ['onBlur'],
      monthsLeft: ['onBlur'],
      upb: ['onBlur'],
      ir: ['onBlur'],
      pmt: ['onBlur'],
      dueDiligenceCost: ['onBlur'],
      monthlyServicingCost: ['onBlur'],
    },
    scenario: {
      upb_1: ['onBlur'],
      yield_1: ['onBlur'],
      price_1: ['onBlur'],
      upb_2: ['onBlur'],
      yield_2: ['onBlur'],
      price_2: ['onBlur'],
      upb_3: ['onBlur'],
      yield_3: ['onBlur'],
      price_3: ['onBlur'],
    },
  })

  const validateZipCode = (rule, value) => {
    const zipCodeRegex = /^\d{5}$/

    if (value && !zipCodeRegex.test(value)) {
      return Promise.reject('Please enter a 5-digit zip code in number format (e.g., 23300).')
    }

    return Promise.resolve()
  }

  const onFinish = (values) => {
    console.log(values)
  }
  function roundToTwoDecimals(num) {
    return num.toFixed(2)
  }
  const makeCalculations = (allFields) => {
    let tempCalculations = { ...calculations }
    let yield_1 = null,
      yield_3 = null,
      price_1 = null,
      price_2 = null,
      upb_2 = null,
      upb_3 = null
    allFields.map((item, index) => {
      if (!item?.error?.length && /* item.touched &&*/ item.value?.toString()) {
        if (tempCalculations[`${item.name[1]}`]) {
          tempCalculations[`${item.name[1]}`] = item.value
        } else {
          tempCalculations[`${item.name[1]}`] = item.value
        }
      } else {
        if (tempCalculations[`${item.name[1]}`]) {
          delete tempCalculations[`${item.name[1]}`]
        } else {
          delete tempCalculations[`${item.name[1]}`]
        }
      }
    })

    // START Type Parsing
    if (tempCalculations?.monthsLeft) {
      tempCalculations.monthsLeft = parseInt(tempCalculations.monthsLeft)
    }
    if (tempCalculations?.upb) {
      tempCalculations.upb = parseFloat(tempCalculations.upb)
    }
    if (tempCalculations?.ir) {
      tempCalculations.ir = parseFloat(tempCalculations.ir)
    }
    if (tempCalculations?.pmt) {
      tempCalculations.pmt = parseFloat(tempCalculations.pmt)
    }
    if (tempCalculations?.dueDiligenceCost) {
      tempCalculations.dueDiligenceCost = parseFloat(tempCalculations.dueDiligenceCost)
    }
    if (tempCalculations?.monthlyServicingCost) {
      tempCalculations.monthlyServicingCost = parseFloat(tempCalculations.monthlyServicingCost)
    }

    if (tempCalculations?.price_1) {
      tempCalculations.price_1 = parseFloat(tempCalculations.price_1)
    }
    if (tempCalculations?.price_2) {
      tempCalculations.price_2 = parseFloat(tempCalculations.price_2)
    }
    if (tempCalculations?.price_3) {
      tempCalculations.price_3 = parseFloat(tempCalculations.price_3)
    }
    if (tempCalculations?.yield_1) {
      tempCalculations.yield_1 = parseFloat(tempCalculations.yield_1)
    }
    if (tempCalculations?.yield_2) {
      tempCalculations.yield_2 = parseFloat(tempCalculations.yield_2)
    }
    if (tempCalculations?.yield_3) {
      tempCalculations.yield_3 = parseFloat(tempCalculations.yield_3)
    }
    if (tempCalculations?.upb_1) {
      tempCalculations.upb_1 = parseFloat(tempCalculations.upb_1)
    }
    if (tempCalculations?.upb_2) {
      tempCalculations.upb_2 = parseFloat(tempCalculations.upb_2)
    }
    if (tempCalculations?.upb_3) {
      tempCalculations.upb_3 = parseFloat(tempCalculations.upb_3)
    }
    // End Type Parsing

    //Start All Calculations
    if (
      tempCalculations?.monthsLeft &&
      tempCalculations?.upb &&
      tempCalculations?.ir &&
      tempCalculations?.pmt
    ) {
      setDisableRunScenario(false)
      if (
        tempCalculations?.monthsLeft >= 0 &&
        tempCalculations?.pmt >= 0 &&
        tempCalculations?.monthlyServicingCost >= 0 &&
        tempCalculations?.upb >= 0 &&
        tempCalculations?.upb_1 > 0 &&
        tempCalculations?.dueDiligenceCost >= 0
      ) {
        // 1- START Calculate yeild_1 from UPB
        try {
          yield_1 =
            rate(
              tempCalculations.monthsLeft,
              tempCalculations.pmt - tempCalculations.monthlyServicingCost,
              -(
                tempCalculations.upb * (tempCalculations.upb_1 / 100) +
                tempCalculations.dueDiligenceCost
              ),
              0,
            ) *
            12 *
            100
          if (!isNaN(yield_1)) {
            tempCalculations['yield_1'] = yield_1
          } else {
            tempCalculations['yield_1'] = null
          }
        } catch (e) {
          tempCalculations['yield_1'] = null
        }
      } else {
        tempCalculations['yield_1'] = null
      }
      // 1- END Calculate yeild_1 from UPB

      // 2- START Calculate price_1 from UPB
      if (tempCalculations?.upb?.toString() && tempCalculations?.upb_1 > 0) {
        try {
          price_1 = (tempCalculations?.upb * tempCalculations?.upb_1) / 100
          if (!isNaN(price_1)) {
            tempCalculations['price_1'] = price_1
          } else {
            tempCalculations['price_1'] = null
          }
        } catch (error) {
          tempCalculations['price_1'] = null
        }
      } else {
        tempCalculations['price_1'] = null
      }
      // 2- END Calculate price_1 from UPB

      // 3- START Calculate price_2 from Yield
      if (
        tempCalculations?.yield_2 > 0 &&
        tempCalculations?.monthsLeft >= 0 &&
        tempCalculations?.pmt >= 0 &&
        tempCalculations?.monthlyServicingCost >= 0 &&
        tempCalculations?.dueDiligenceCost >= 0
      ) {
        try {
          price_2 =
            -pv(
              tempCalculations?.yield_2 / 100 / 12,
              tempCalculations?.monthsLeft,
              tempCalculations.pmt - tempCalculations.monthlyServicingCost,
              0,
            ) - tempCalculations?.dueDiligenceCost
          if (!isNaN(price_2)) {
            tempCalculations['price_2'] = price_2
          } else {
            tempCalculations['price_2'] = null
          }
        } catch (e) {
          tempCalculations['price_2'] = null
        }
      } else {
        tempCalculations['price_2'] = null
      }
      // 3- END Calculate upb_2 from Yield

      // 4- START Calculate upb_2 from Yield
      if (tempCalculations?.price_2 > 0 && tempCalculations?.upb >= 0) {
        upb_2 = (tempCalculations?.price_2 / tempCalculations?.upb) * 100
        if (!isNaN(upb_2)) {
          tempCalculations['upb_2'] = upb_2
        } else {
          tempCalculations['upb_2'] = null
        }
      } else {
        tempCalculations['upb_2'] = null
      }
      // 4- END Calculate price_2 from Yield

      // 5- START Calculate upb_3 from Price
      if (tempCalculations?.price_3 > 0 && tempCalculations?.upb?.toString()) {
        try {
          upb_3 = (tempCalculations?.price_3 / tempCalculations?.upb) * 100
          if (!isNaN(upb_3)) {
            tempCalculations['upb_3'] = upb_3
          } else {
            tempCalculations['upb_3'] = null
          }
        } catch (error) {
          tempCalculations['upb_3'] = null
        }
      } else {
        tempCalculations['upb_3'] = null
      }
      // 5- End Calculate upb_3 from End

      // 6- START Calculate yeild_3 from Price
      if (
        tempCalculations?.monthsLeft >= 0 &&
        tempCalculations?.pmt >= 0 &&
        tempCalculations?.monthlyServicingCost >= 0 &&
        tempCalculations?.price_3 > 0 &&
        tempCalculations?.dueDiligenceCost >= 0
      ) {
        try {
          yield_3 =
            rate(
              tempCalculations.monthsLeft,
              tempCalculations.pmt - tempCalculations.monthlyServicingCost,
              -(tempCalculations.price_3 + tempCalculations.dueDiligenceCost),
              0,
            ) *
            12 *
            100
          if (!isNaN(yield_3)) {
            tempCalculations['yield_3'] = yield_3
          } else {
            tempCalculations['yield_3'] = null
          }
        } catch (e) {
          tempCalculations['yield_3'] = null
        }
      } else {
        tempCalculations['yield_3'] = null
      }
      // 6- END Calculate yeild_3 from Price
    } else {
      setDisableRunScenario(true)
    }

    setCalculations({ ...tempCalculations })
  }
  const handleFieldsChange = (_, allFields) => {
    makeCalculations(allFields)
    // console.log('allFields', allFields)
    if (_?.length) {
      const currentObj = _[0]
      if (currentObj?.validated && currentObj?.errors?.length && currentObj?.name?.length) {
        const main = currentObj.name[0]
        const sec = currentObj.name[1]
        const validator = validationTrigger
        if (!validator[`${main}`][`${sec}`].includes('onChange')) {
          validator[`${main}`][`${sec}`].push('onChange')
          setValidationTrigger({
            property: validator.property,
            scenario: validator.scenario,
          })
        }
      }
    }
  }
  const numberFormator = (value) => {
    const numericValue = value.replace(/[^0-9.]/g, '')
    return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const handleRunAnotherScenario = async () => {
    setLoading(true)
    const { property: propertyData, scenario: scenarioData } = form.getFieldsValue()
    if (
      propertyData?.monthsLeft?.toString() &&
      propertyData?.pmt?.toString() &&
      propertyData?.upb?.toString() &&
      propertyData?.ir?.toString()
    ) {
      try {
        let config = {
          method: 'POST',
          url: `${process.env.REACT_APP_BASE_URL}/performanceProperty`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(propertyData),
        }

        const propertyRes = await axios.request(config)
        if (propertyRes?.status == 201 && propertyRes?.data?.data?.id) {
          showSuccessNotification('Success', 'Property is created successfully')
          if (
            scenarioData?.upb_1?.toString() ||
            (scenarioData?.yield_2?.toString() && scenarioData?.price_3?.toString())
          ) {
            try {
              let scenarioConfig = {
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/performanceScenario`,
                headers: {
                  'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                  ...scenarioData,
                  upb_2: calculations?.upb_2,
                  upb_3: calculations?.upb_3,
                  yield_1: calculations?.yield_1,
                  yield_3: calculations?.yield_3,
                  price_1: calculations?.price_1,
                  price_2: calculations?.price_2,
                  property_id: propertyRes.data.data.id,
                }),
              }

              const scenarioRes = await axios.request(scenarioConfig)
              if (scenarioRes?.status == 201 && scenarioRes?.data?.data?.id) {
                showSuccessNotification('Success', 'Outcome is created successfully')
              } else {
                showErrorNotification('Error', 'Error while creating Outcome')
              }
              console.log('scenarioRes', scenarioRes)
            } catch (e) {
              showErrorNotification('Error', 'Error while creating Outcome')
            }
          }
          navigate(`/performingNote/${propertyRes.data.data.id}`)
        } else {
          showErrorNotification('Error', 'Error while creating Property')
        }
      } catch (error) {
        showErrorNotification('Error', 'Error while creating Property')
      }
    }
    setLoading(false)
  }
  useEffect(() => {
    if (upbEditable && upbRef.current) {
      upbRef.current.focus()
    }
  }, [upbEditable])
  useEffect(() => {
    if (yieldEditable && yieldRef.current) {
      yieldRef.current.focus()
    }
  }, [yieldEditable])
  useEffect(() => {
    if (priceEditable && priceRef.current) {
      priceRef.current.focus()
    }
  }, [priceEditable])

  return (
    <div className='w-full lg:w-[83%] lg:p-3 sm:p-0 mx-auto property-form mt-0 home-form'>
      <div className='w-full md:w-3/4 lg:w-1/2 border border-[#2F2B431A] bg-[#FCFCFD] lg:rounded-[24px] sm:rounded-none sm:mx-2 md:px-[32px] py-[24px] mx-auto'>
        <button
          onClick={async () => {
            // await submit()
          }}
        ></button>
        <Form
          layout='inline'
          {...formProps}
          onFinish={onFinish}
          onFieldsChange={handleFieldsChange}
          className='flex flex-col mx-2 md:mx-8'
          style={{ maxWidth: '100%', md: 600 }}
          disabled={loading}
        >
          {/* Start <--> Property Address Inputs */}
          <div className='flex flex-col w-full text-gray-700 lg:ml-0 md:ml-0 ml-2'>
            <h1 className='my-2 text-sm font-medium leading-5'>Property Address</h1>
            <div className='grid md:grid-cols-2 sm:grid-cols-1 gap-y-4 w-full '>
              {/* Start <--> Address Field */}
              <InputField
                objName={'property'}
                fieldName={'address'}
                rules={[
                  // {
                  //   required: true,
                  //   message: 'Address is required!',
                  // },
                  {
                    min: 7,
                    message: 'Address should be minimum 7 letters!',
                  },
                ]}
                validationTrigger={validationTrigger}
                placeholder={'Enter Address'}
              />
              {/* End <--> Address Field */}
              {/* Start <--> City Field */}
              <InputField
                objName={'property'}
                fieldName={'city'}
                rules={[
                  // {
                  //   required: true,
                  //   message: 'City is required!',
                  // },
                  {
                    min: 2,
                    message: 'City should be minimum 2 letters!',
                  },
                ]}
                validationTrigger={validationTrigger}
                placeholder={'City'}
              />
              {/* End <--> City Field */}
              {/* Start <--> State Field */}
              <Form.Item
                name={['property', 'state']}
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: 'State is required!',
                    // },
                  ]
                }
                className='col-span-1'
              >
                <AutoComplete
                  placeholder='Select State'
                  className='w-full'
                  options={usStates
                    .filter((stateName) =>
                      stateName.toLowerCase().startsWith(inputValue.toLowerCase()),
                    )
                    .map((stateName) => ({
                      value: stateName,
                      label: stateName,
                    }))}
                  onSearch={(value) => setInputValue(value)}
                />
              </Form.Item>
              {/* End <--> State Field */}
              {/* Start <--> Zip Code Field */}
              <Form.Item
                name={['property', 'zipcode']}
                rules={[
                  // {
                  //   required: true,
                  //   message: 'Zip Code is required!',
                  // },
                  {
                    validator: validateZipCode,
                  },
                ]}
                validateTrigger={validationTrigger.property.zipcode}
                className='col-span-1'
              >
                <Input
                  controls='false'
                  placeholder='Zipcode'
                  className='w-full numberInput'
                />
              </Form.Item>
              {/* End <--> Zip Code Field */}
            </div>
          </div>
          <div className='flex flex-col'>
            {/* start <--> Property Financial Inputs */}
            <div className='flex flex-col text-gray-700 lg:ml-0 md:ml-0 ml-2'>
              {/* <h1 className='mt-6 text-base font-semibold'>Holding costs</h1> */}
              <div className='grid grid-cols-1 md:grid-cols-2 gap-y-4 my-2 numberInput'>
                <div className='col-span-2 md:col-span-1'>
                  {/* Start <--> Months Left Input Field */}
                  <h2 className='mb-1 font-medium text-sm '>(N) Months Left</h2>
                  <Form.Item
                    name={['property', 'monthsLeft']}
                    rules={[
                      {
                        required: true,
                        message: 'Number of months is required!',
                      },
                    ]}
                    validateTrigger={validationTrigger.property.monthsLeft}
                    className='col-span-1'
                  >
                    <InputNumber
                      controls='false'
                      placeholder='Months'
                      className='w-full numberInput'
                    />
                  </Form.Item>
                  {/* Start <--> Months Left Input Field */}
                </div>
                <div className='col-span-2 md:col-span-1'>
                  {/* Start <--> Loan Amount Left Input Field */}
                  <h2 className='mb-1 font-medium text-sm '>UPB (Loan Amount Left)</h2>
                  <Form.Item
                    name={['property', 'upb']}
                    rules={[
                      {
                        required: true,
                        message: 'UPB is required!',
                      },
                      {
                        type: 'number',
                        message: 'UPB Rate must be a number',
                        min: 0,
                        message: 'UPB Rate must be greater than or equal to 0!',
                      },
                    ]}
                    validateTrigger={validationTrigger.property.upb}
                    className='my-2'
                  >
                    <InputNumber
                      defaultValue={0}
                      prefix='$'
                      className='w-full'
                      controls={false}
                      placeholder='00.00'
                      formatter={numberFormator}
                    />
                  </Form.Item>
                </div>
                {/* End <--> Loan Amount Left Input  Field */}

                {/* Start <--> Interest Rate input Field */}
                <div className='col-span-2 md:col-span-1'>
                  <h2 className='mb-1 font-medium text-sm'>I/YR (Interest Rate)</h2>
                  <Form.Item
                    name={['property', 'ir']}
                    rules={[
                      {
                        required: true,
                        message: 'Interest Rate is required!',
                      },
                      {
                        type: 'number',
                        message: 'Interest Rate must be a number',
                        max: 99,
                        message: 'Interest Rate must be less than 100!',
                      },
                      {
                        type: 'number',
                        message: 'Interest Rate must be a number',
                        min: 0,
                        message: 'Interest Rate must be greater than or equal to 0!',
                      },
                    ]}
                    validateTrigger={validationTrigger.property.ir}
                  >
                    <InputNumber
                      defaultValue={0}
                      suffix='%'
                      className='w-full numberInput'
                      controls={false}
                      placeholder='00.00'
                    />
                  </Form.Item>
                </div>
                {/* end <--> Interest Rate input Field */}

                {/* Start <--> Monthly Payment input Field */}
                <div className='col-span-2 md:col-span-1'>
                  <h2 className='mb-1 font-medium text-sm'>PMT (Monthly Payment)</h2>
                  <Form.Item
                    name={['property', 'pmt']}
                    rules={[
                      {
                        required: true,
                        message: 'Monthly Payment is required!',
                      },
                    ]}
                    validateTrigger={validationTrigger.property.pmt}
                    className='my-2'
                  >
                    <InputNumber
                      defaultValue={0}
                      prefix='$'
                      className='w-full'
                      controls={false}
                      placeholder='00.00'
                      formatter={numberFormator}
                    />
                  </Form.Item>
                </div>
                {/* end <--> Monthly Payment input Field */}

                {/* Start <--> Due Diligence Cost input Field */}
                <div className='col-span-2 md:col-span-1'>
                  <h2 className='mb-1 font-medium text-sm'>Due Diligence Cost</h2>
                  <Form.Item
                    name={['property', 'dueDiligenceCost']}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'is required!',
                    //   },
                    // ]}
                    validateTrigger={validationTrigger.property.dueDiligenceCost}
                    className='my-2'
                  >
                    <InputNumber
                      defaultValue={0}
                      prefix='$'
                      className='w-full'
                      controls={false}
                      placeholder='00.00'
                      formatter={numberFormator}
                    />
                  </Form.Item>
                </div>
                {/* End <--> Due Diligence Cost input Field */}
                {/* Start <--> Monthly Servicing Cost input Field */}
                <div className='col-span-2 md:col-span-1'>
                  <h2 className='mb-1 font-medium text-sm'>Monthly Servicing Cost</h2>
                  <Form.Item
                    name={['property', 'monthlyServicingCost']}
                    validateTrigger={validationTrigger.property.monthlyServicingCost}
                    className='my-2'
                  >
                    <InputNumber
                      defaultValue={0}
                      prefix='$'
                      className='w-full'
                      controls={false}
                      placeholder='00.00'
                      formatter={numberFormator}
                    />
                  </Form.Item>
                </div>
                {/* End <--> Monthly Servicing Cost input Field */}
                {/* Start <--> Monthly Servicing Cost input Field */}
                <div className='col-span-2 mr-[16px] md:mr-0'>
                  <div className='mt-8'>
                    <h2 className='mb-4 md:mb-1 font-semibold text-[14px]'>Outcome % of UPB</h2>
                    <div className='flex flex-col md:flex-row md:justify-between items-center md:items-center'>
                      <div
                        className={`w-full md:w-[25%] my-2 md:my-0 ${upbEditable ? 'bg-[#D0E6FF]' : 'bg-[#8d8d8d1a]'} rounded flex flex-col justify-start items-center min-h-[115px] pt-[25px]`}
                      >
                        <h2 className='mb-1 font-normal text-[14px] text-[#667085]'>% of UPB</h2>

                        <span className={`${upbEditable ? '' : 'hidden'}`}>
                          <Form.Item
                            name={['scenario', 'upb_1']}
                            rules={[
                              {
                                type: 'number',
                                message: 'UBP % must be a number',
                                max: 99,
                                message: 'UBP % must be less than 100!',
                              },
                              {
                                type: 'number',
                                message: 'UBP % must be a number',
                                min: 0,
                                message: 'UBP % must be greater than or equal to 0!',
                              },
                            ]}
                            validateTrigger={validationTrigger.scenario.upb_1}
                            className='mx-auto'
                          >
                            <InputNumber
                              suffix='%'
                              className='numberInput'
                              controls={false}
                              placeholder='00.00'
                              ref={upbRef}
                              onBlur={() => {
                                setUpbEditable(false)
                              }}
                            />
                          </Form.Item>
                        </span>
                        <span
                          className={`${upbEditable ? 'hidden' : ''} font-normal text-[14px] text-[#667085]`}
                        >
                          {calculations?.upb_1 ? (
                            <>{numberFormator(roundToTwoDecimals(calculations.upb_1))} %</>
                          ) : (
                            <span>0.00 %</span>
                          )}
                        </span>
                        <span
                          className={`${upbEditable ? 'hidden' : 'cursor-pointer'} font-semibold text-[13px] text-[#6941C6]`}
                          onClick={() => {
                            setUpbEditable(true)
                          }}
                        >
                          Edit Value
                        </span>
                      </div>
                      <div className='w-full md:w-[25%] my-2 md:my-0 rounded bg-[#8d8d8d1a] flex flex-col justify-start items-center min-h-[115px] pt-[25px]'>
                        <span className='mx-2 font-normal text-[14px] text-[#667085]'>
                          % of Yield
                        </span>
                        <span className='mx-2 mt-1 font-normal text-[14px] text-[#667085]'>
                          {calculations?.yield_1 ? (
                            <>{numberFormator(roundToTwoDecimals(calculations.yield_1))} %</>
                          ) : (
                            <span>0.00 %</span>
                          )}
                        </span>
                      </div>
                      <div className='w-full md:w-[25%] my-2 md:my-0 rounded bg-[#8d8d8d1a] flex flex-col justify-start items-center min-h-[115px] pt-[25px]'>
                        <span className='mx-2 font-normal text-[14px] text-[#667085]'>Price</span>
                        <span className='mx-2 mt-1 font-normal text-[14px] text-[#667085]'>
                          {calculations?.price_1 ? (
                            <>$ {numberFormator(roundToTwoDecimals(calculations.price_1))}</>
                          ) : (
                            <span>$ 0.00</span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='mt-8'>
                    <h2 className='mb-4 md:mb-1 font-semibold text-[14px]'>Outcome % of Yield</h2>
                    <div className='flex flex-col md:flex-row md:justify-between items-center md:items-center'>
                      <div
                        className={`w-full md:w-[25%] my-2 md:my-0 ${yieldEditable ? 'bg-[#D0E6FF]' : 'bg-[#8d8d8d1a]'} rounded flex flex-col justify-start items-center min-h-[115px] pt-[25px]`}
                      >
                        <h2 className='mb-1 font-normal text-[14px] text-[#667085]'>% of Yield</h2>
                        <span className={`${yieldEditable ? '' : 'hidden'}`}>
                          <Form.Item
                            name={['scenario', 'yield_2']}
                            rules={[
                              {
                                type: 'number',
                                message: 'Yield % must be a number',
                                max: 99,
                                message: 'Yield % must be less than 100!',
                              },
                              {
                                type: 'number',
                                message: 'Yield % must be a number',
                                min: 0,
                                message: 'Yield % must be greater than or equal to 0!',
                              },
                            ]}
                            validateTrigger={validationTrigger.scenario.yield_2}
                            className='mx-auto'
                          >
                            <InputNumber
                              suffix='%'
                              className='numberInput'
                              controls={false}
                              placeholder='00.00'
                              ref={yieldRef}
                              onBlur={() => {
                                setYieldEditable(false)
                              }}
                            />
                          </Form.Item>
                        </span>
                        <span
                          className={`${yieldEditable ? 'hidden' : ''} font-normal text-[14px] text-[#667085]`}
                        >
                          {calculations?.yield_2 ? (
                            <>{numberFormator(roundToTwoDecimals(calculations.yield_2))} %</>
                          ) : (
                            <span>0.00 %</span>
                          )}
                        </span>
                        <span
                          className={`${yieldEditable ? 'hidden' : 'cursor-pointer'} font-semibold text-[13px] text-[#6941C6]`}
                          onClick={() => {
                            setYieldEditable(true)
                          }}
                        >
                          Edit Value
                        </span>
                      </div>
                      <div className='w-full md:w-[25%] my-2 md:my-0 rounded bg-[#8d8d8d1a] flex flex-col justify-start items-center min-h-[115px] pt-[25px]'>
                        {' '}
                        <span className='mx-2 font-normal text-[14px] text-[#667085]'>
                          % of UPB
                        </span>
                        <span className='mx-2 mt-1 font-normal text-[14px] text-[#667085]'>
                          {calculations?.upb_2 ? (
                            <>{numberFormator(roundToTwoDecimals(calculations.upb_2))} %</>
                          ) : (
                            <span>0.00 %</span>
                          )}
                        </span>
                      </div>
                      <div className='w-full md:w-[25%] my-2 md:my-0 rounded bg-[#8d8d8d1a] flex flex-col justify-start items-center min-h-[115px] pt-[25px]'>
                        {' '}
                        <span className='mx-2 font-normal text-[14px] text-[#667085]'>Price</span>
                        <span className='mx-2 mt-1 font-normal text-[14px] text-[#667085]'>
                          {calculations?.price_2 ? (
                            <>$ {numberFormator(roundToTwoDecimals(calculations.price_2))}</>
                          ) : (
                            <span>$ 0.00</span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='mt-8'>
                    <h2 className='mb-4 md:mb-1 font-semibold text-[14px]'>Price</h2>
                    <div className='flex flex-col md:flex-row md:justify-between items-center md:items-center'>
                      <div
                        className={`w-full md:w-[25%] my-2 md:my-0 ${priceEditable ? 'bg-[#D0E6FF]' : 'bg-[#8d8d8d1a]'} rounded flex flex-col justify-start items-center min-h-[115px] pt-[25px]`}
                      >
                        <h2 className='mb-1 font-normal text-[14px] text-[#667085]'>Price</h2>

                        <span className={`${priceEditable ? '' : 'hidden'}`}>
                          <Form.Item
                            name={['scenario', 'price_3']}
                            rules={[
                              {
                                required: true,
                                message: 'UPB is required!',
                              },
                            ]}
                            validateTrigger={validationTrigger.scenario.price_3}
                            className='mx-auto'
                          >
                            <InputNumber
                              defaultValue={0}
                              prefix='$'
                              className='numberInput'
                              controls='false'
                              placeholder='00'
                              formatter={numberFormator}
                              ref={priceRef}
                              onBlur={() => {
                                setPriceEditable(false)
                              }}
                            />
                          </Form.Item>
                        </span>
                        <span
                          className={`${priceEditable ? 'hidden' : ''} font-normal text-[14px] text-[#667085]`}
                        >
                          {calculations?.price_3 ? (
                            <>$ {numberFormator(roundToTwoDecimals(calculations.price_3))}</>
                          ) : (
                            <span>$ 0.00</span>
                          )}
                        </span>
                        <span
                          className={`${priceEditable ? 'hidden' : 'cursor-pointer'} font-semibold text-[13px] text-[#6941C6]`}
                          onClick={() => {
                            setPriceEditable(true)
                          }}
                        >
                          Edit Value
                        </span>
                      </div>
                      <div className='w-full md:w-[25%] my-2 md:my-0 rounded bg-[#8d8d8d1a] flex flex-col justify-start items-center min-h-[115px] pt-[25px]'>
                        {' '}
                        <span className='mx-2 font-normal text-[14px] text-[#667085]'>
                          % of UPB
                        </span>
                        <span className='mx-2 mt-1 font-normal text-[14px] text-[#667085]'>
                          {calculations?.upb_3 ? (
                            <>{numberFormator(roundToTwoDecimals(calculations.upb_3))} %</>
                          ) : (
                            <span>0.00 %</span>
                          )}
                        </span>
                      </div>
                      <div className='w-full md:w-[25%] my-2 md:my-0 rounded bg-[#8d8d8d1a] flex flex-col justify-start items-center min-h-[115px] pt-[25px]'>
                        {' '}
                        <span className='mx-2 font-normal text-[14px] text-[#667085]'>
                          % of Yield
                        </span>
                        <span className='mx-2 mt-1 font-normal text-[14px] text-[#667085]'>
                          {calculations?.yield_3 ? (
                            <>{numberFormator(roundToTwoDecimals(calculations.yield_3))} %</>
                          ) : (
                            <span>0.00 %</span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End <--> Monthly Servicing Cost input Field */}
              </div>
            </div>
            {/* End <--> Property Financial Inputs */}
          </div>
          {/* End <--> Property Address Inputs */}
          <div className='flex flex-col'>
            <div className='flex flex-col md:flex-row lg:flex-row w-full justify-center p-4 gap-4'>
              {/* <Button
                disabled={true}
                className='md:w-48 lg:w-48 w-full h-10 font-bold justify-center items-center'
              >
                Delete this Scenario
              </Button> */}
              <Button
                className='md:w-48 lg:w-48 w-full h-10 font-bold text-white bg-[#7F56D9] justify-center items-center'
                onClick={handleRunAnotherScenario}
                disabled={disableRunScenario}
              >
                Run Another Scenario
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}
export default PerformingNotesForm
