import React, { useEffect, useRef, useState } from 'react'
import { Button, Rate } from 'antd'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './styles.scss'
import axios from 'axios'
import Spinner from '../../assets/svg/Spinner'

const CarouselItem = ({ index, testimonial }) => {
  return (
    <div
      className='group bg-[#F5F5F5] hover:bg-[#CEE5FF] p-6 gap-x-2 h-full'
      key={`caroselItem${index}`}
    >
      <div className='items-center mb-6'>
        <h2 className='text-lg font-bold'>
          {testimonial?.name}
          {testimonial?.name && testimonial?.companyName && ' - '}
          {testimonial?.companyName}
        </h2>
      </div>
      <div className='flex items-center mb-6 '>
        <Rate value={testimonial?.rating} disabled defaultValue={1} className='mr-2' />
        <p>
          {testimonial?.rating}/{testimonial?.maxRating}
        </p>
      </div>
      <p className='text-gray-600'>{testimonial?.review}</p>
    </div>
  )
}
const ClientsCarasoul = ({ title }) => {
  const carouselRef = useRef(null)
  const carouselRefMbl = useRef(null)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next()
    }
  }

  const handlePrevious = () => {
    if (carouselRef.current) {
      carouselRef.current.previous()
    }
  }
  const handleNextMbl = () => {
    if (carouselRefMbl.current) {
      carouselRefMbl.current.next()
    }
  }

  const handlePreviousMbl = () => {
    if (carouselRefMbl.current) {
      carouselRefMbl.current.previous()
    }
  }

  const [pageData, setPageData] = useState([])
  const [loading, setLoading] = useState(false)
  const getPageData = async () => {
    setLoading(true)
    try {
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/testimonials?populate=*`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.length > 0) {
        setPageData(res.data.data)
      }
    } catch (error) {
      console.log('error in fetch blogs', error)
    }
    setLoading(false)
    return true
  }
  useEffect(() => {
    getPageData()
  }, [])
  return (
    <>
      {loading ? (
        <div className='flex w-full items-center justify-center min-h-[300px]'>
          <Spinner />{' '}
        </div>
      ) : (
        <>
          {pageData?.length > 0 && (
            <div className='flex justify-center items-center w-full py-8 mx-auto lg:my-10'>
              <div className='max-w-6xl w-full min-w-0 sm:min-w-full lg:min-w-3xl'>
                <div className='flex flex-col lg:flex-row  items-center'>
                  <h1 className='text-xl md:text-3xl lg:text-4xl font-bold text-center lg:text-left'>
                    {title ?? 'What Our Clients Said about us'}
                  </h1>
                  <div className='hidden md:flex justify-center lg:mt-0 lg:justify-start lg:ml-16 space-x-4'>
                    <Button
                      onClick={handlePrevious}
                      icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />}
                      className='bg-white rounded-full p-4'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '24px',
                        height: '50px',
                        width: '50px',
                      }}
                    />
                    <Button
                      onClick={handleNext}
                      icon={<ArrowRightOutlined style={{ fontSize: '20px' }} />}
                      className='bg-white rounded-full p-4'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '24px',
                        height: '50px',
                        width: '50px',
                      }}
                    />
                  </div>
                  <div className='flex md:hidden justify-center lg:mt-0 lg:justify-start lg:ml-16 space-x-4'>
                    <Button
                      onClick={handlePreviousMbl}
                      icon={<ArrowLeftOutlined style={{ fontSize: '20px' }} />}
                      className='bg-white rounded-full p-4'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '24px',
                        height: '50px',
                        width: '50px',
                      }}
                    />
                    <Button
                      onClick={handleNextMbl}
                      icon={<ArrowRightOutlined style={{ fontSize: '20px' }} />}
                      className='bg-white rounded-full p-4'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '24px',
                        height: '50px',
                        width: '50px',
                      }}
                    />
                  </div>
                </div>
                <div
                  className='hidden md:block w-full py-8 sm:py-16 lg:py-32'
                  key='largeCaroselItem'
                >
                  <Carousel
                    ref={carouselRef}
                    responsive={responsive}
                    itemClass='carousel-item-gap'
                    customLeftArrow={<div />}
                    customRightArrow={<div />}
                    infinite={true}
                    draggable={true}
                    swipeable={true}
                    autoPlay={true}
                    keyBoardControl={true}
                    transitionDuration={500}
                    containerClass='carousel-container mx-auto'
                    centerMode={true}
                  >
                    {pageData?.length > 0 &&
                      pageData?.map((testimonial, index) => (
                        <CarouselItem index={index} testimonial={testimonial?.attributes} />
                      ))}
                  </Carousel>
                </div>
                <div
                  className='block md:hidden w-full py-8 sm:py-16 lg:py-32'
                  key='smallCaroselItem'
                >
                  <Carousel
                    ref={carouselRefMbl}
                    responsive={responsive}
                    itemClass='carousel-item-gap'
                    customLeftArrow={<div />}
                    customRightArrow={<div />}
                    infinite={true}
                    draggable={true}
                    swipeable={true}
                    autoPlay={true}
                    keyBoardControl={true}
                    transitionDuration={500}
                    containerClass='carousel-container mx-auto'
                    centerMode={false}
                  >
                    {pageData?.length > 0 &&
                      pageData?.map((testimonial, index) => (
                        <CarouselItem index={index} testimonial={testimonial?.attributes} />
                      ))}
                  </Carousel>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
export default ClientsCarasoul
