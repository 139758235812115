import { useAuth } from '../../../hooks/useAppContext'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Button, Drawer, Dropdown, Menu } from 'antd'
import Logo from '../../../assets/svg/Logo'
import PhoneIcon from '../../../assets/svg/PhoneIcon'
import { MenuOutlined } from '@ant-design/icons'
import './styles.scss'

const Header = () => {
  const { isLoggedIn } = useAuth()
  const [menuOpen, setMenuOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState({})
  const [menu, setMenu] = useState({})
  const [authMenu, setAuthMenu] = useState({})
  const navigate = useNavigate()

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const links = [
    {
      title: 'About us',
      path: '/about',
    },
    {
      title: 'Blogs',
      path: '/blog',
    },
  ]
  const getPageData = async () => {
    setLoading(true)
    try {
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/headers/1`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            links: {
              populate: ['title', 'path'],
            },
          },
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.id && res?.data?.data?.attributes) {
        setPageData(res.data.data.attributes)
        const menuItems = res.data.data.attributes?.links?.map((item, index) => ({
          key: item.path,
          label: <Link to={item.path}>{item.title}</Link>,
        }))
        const menuButton = {
          key: 'get-a-quote',
          label: (
            <button
              className='bg-white px-3 py-2 rounded-lg flex justify-between items-center border w-[130px] sm:w-auto'
              onClick={() => {
                window.open(
                  'https://calendly.com/bluhatbookkeeping/15min_consultation_bookkeeping_services',
                  '_blank',
                )
              }}
            >
              <span className='mr-[6px]'>
                <PhoneIcon />
              </span>
              <span className='font-medium text-[14px] cursor-pointer'>Book A Call</span>
            </button>
          ),
        }
        const accessCalc = {
          key: '/leadGen',
          label: <Link to='/leadGen'>Access Calculator</Link>,
        }
        const selectCalc = {
          key: 'select-calculator',
          label: 'Select Calculator',
          children: [
            {
              key: '/property',
              label: <Link to='/property'>Fix and Flip</Link>,
            },
            {
              key: '/performingNote',
              label: <Link to='/performingNote'>Performing Note</Link>,
            },
          ],
        }
        setMenu({ items: [...menuItems, menuButton, accessCalc] })
        setAuthMenu({ items: [...menuItems, menuButton, selectCalc] })
      }
    } catch (error) {
      console.log('error in fetch blogs', error)
    }
    setLoading(false)
    return true
  }
  useEffect(() => {
    getPageData()
  }, [])

  return (
    <div className='top-0 left-0 right-0 border header'>
      <nav className='flex flex-wrap items-center justify-between w-5/6 py-5 mx-auto md:py-0'>
        <div className='flex items-center justify-between w-full sm:w-auto'>
          <span
            className='flex items-center justify-between w-auto cursor-pointer'
            onClick={() => {
              navigate('/')
            }}
          >
            <span className='mb-1 mr-2'>
              <Logo />
            </span>
            <span className='font-bold text-lg sm:text-xl md:text-2xl text-[#1D2939]'>
              Blu Hat Bookkeeping
            </span>
          </span>
          {/* <button
            onClick={toggleMenu}
            className='block text-gray-500 sm:hidden hover:text-gray-600 focus:outline-none'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              id='menu-button'
              class='h-6 w-6 cursor-pointer md:hidden block'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2'
                d='M4 6h16M4 12h16M4 18h16'
              />
            </svg>
          </button> */}
          <div className='lg:hidden'>
            {menu?.items?.length > 0 && (
              <>
                {isLoggedIn ? (
                  <Dropdown menu={authMenu} trigger={['click']}>
                    <Button
                      type='default'
                      className='block border-none lg:hidden'
                      icon={<MenuOutlined style={{ color: '#2F2B43' }} />}
                    />
                  </Dropdown>
                ) : (
                  <Dropdown menu={menu} trigger={['click']}>
                    <Button
                      type='default'
                      className='block border-none lg:hidden'
                      icon={<MenuOutlined style={{ color: '#2F2B43' }} />}
                    />
                  </Dropdown>
                )}
              </>
            )}
          </div>
        </div>
        {/* Mobile menu items */}
        <div
          className={`${
            menuOpen ? 'block' : 'hidden'
          } sm:flex flex-col sm:flex-row  md:items-center gap-4 text-slate-500 mt-4 sm:mt-0 w-full sm:w-auto`}
        >
          <div>
            <ul
              className='pt-4 md:flex md:justify-between md:pt-0'
            >
              {isLoggedIn && (
                <li className='hidden py-2 cursor-pointer lg:block md:p-4'>
                  {menu?.items?.length > 0 && (
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: '/property',
                            label: <Link to='/property'>Fix and Flip</Link>,
                          },
                          {
                            key: '/performingNote',
                            label: <Link to='/performingNote'>Performing Note</Link>,
                          },
                        ],
                      }}
                      trigger={['click']}
                    >
                      <span className='hover:text-[#6941C6] font-normal text-base text-[#344054] sm:mr-5'>
                        Select Calculator
                      </span>
                    </Dropdown>
                  )}
                </li>
              )}
              {!isLoggedIn && (
                <li className='block py-2 md:p-4' key={`header-accesss-caculator`}>
                  <Link
                    to='/leadgen'
                    className='hover:text-[#6941C6] font-normal text-base text-[#344054] sm:mr-5'
                  >
                    Access Calculator
                  </Link>
                </li>
              )}
              {pageData?.links?.length > 0 &&
                pageData.links.map((link) => {
                  return (
                    <li className='block py-2 md:p-4' key={`header-${link?.path}`}>
                      <Link
                        to={link.path}
                        className='hover:text-[#6941C6] font-normal text-base text-[#344054] sm:mr-5'
                      >
                        {link.title}
                      </Link>
                    </li>
                  )
                })}
            </ul>
          </div>

          <button
            className='bg-white px-3 py-2 rounded-lg flex justify-between items-center border w-[130px] sm:w-auto'
            onClick={() => {
              navigate('/book-a-call')
            }}
          >
            <span className='mr-[6px]'>
              <PhoneIcon />
            </span>
            <span className='font-medium text-[14px] cursor-pointer'>Book A Call</span>
          </button>
        </div>
      </nav>
    </div>
  )
}
export default Header
