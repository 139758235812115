import { useNavigate } from 'react-router-dom'
import BookACall from '../../components/BookACall'
import './styles.scss'
import { useEffect, useState } from 'react'
import axios from 'axios'
import PageLoader from '../../components/PageLoader'

const ContactUs = () => {
  const navigate = useNavigate()
  const [pageData, setPageData] = useState({})
  const [loading, setLoading] = useState(false)
  const getPageData = async () => {
    setLoading(true)
    try {
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/contacts/1`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            contactMethods: {
              populate: ['image'],
            },
            bookACall: {
              populate: ['title'],
            },
          },
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.id && res?.data?.data?.attributes?.publishedAt != null) {
        setPageData(res.data.data.attributes)
      }
    } catch (error) {
      console.log('error in fetch blogs', error)
    }
    setLoading(false)
    return true
  }
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    getPageData()
  }, [])
  const handleBookACall = () => {
    if (pageData?.bookACall?.targetBlank) {
      window.open(`${pageData?.bookACall?.path}`, '_blank')
    } else {
      navigate(`${pageData?.bookACall?.path}`)
    }
  }
  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <div className='flex flex-col justify-center items-center py-0 lg:pt-16 lg:pb-8 my-0 lg:mt-10  w-full'>
            {pageData?.title && (
              <h1 className='mb-[30px] text-[#1D2939] font-semibold text-2xl lg:text-5xl'>
                {pageData?.title}
              </h1>
            )}
            {pageData?.bookACall?.id && (
              <div className=' w-full mx-auto'>
                <BookACall
                  title={pageData?.bookACall?.title}
                  description={pageData?.bookACall?.description}
                  btnText={pageData?.bookACall?.btnText}
                  handleBtnClick={handleBookACall}
                  shadowClass={'customeShadow'}
                />
              </div>
            )}
          </div>
          <div className='flex justify-center items-center py-0 lg:py-10 my-10'>
            <div className='flex justify-between items-center flex-col md:flex-row w-full'>
              {pageData?.contactMethods?.length > 0 &&
                pageData?.contactMethods?.map((item) => {
                  return (
                    <div className='w-full lg:w-[32%] flex flex-col justify-center px-[15px] lg:px-[30px] md:px-0 py-[15px] lg:py-[30px] my-[10px] lg:my-[15px] md:my-0 contact-shadow bg-white rounded-xl'>
                      {(item.image?.data?.attributes?.url ||
                        item?.image?.data?.attributes?.alternativeText) && (
                        <div className='mx-auto bg-[#DFEEFF] w-[70px] h-[70px] lg:w-[100px] lg:h-[100px] flex justify-center items-center rounded-[60px]'>
                          <img
                            src={item.image.data.attributes.url}
                            alt={item.image.data.attributes.alternativeText ?? 'Image'}
                          />
                        </div>
                      )}
                      <h1 className='text-[#101828] font-semibold text-lg lg:text-[22px] text-center mt-[10px]'>
                        {item.title}
                      </h1>
                      <span className='text-[#667085] font-medium text-xs lg:text-[15px] text-center mt-[10px]'>
                        {item.text}
                      </span>
                      <h1 className='text-[#6941C6] font-semibold text-xs lg:text-[15px] text-center mt-[10px]'>
                        {item.description}
                      </h1>
                    </div>
                  )
                })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default ContactUs
