import { useEffect, useState } from 'react'
import ClientsCarasoul from '../../components/ClientsCarasoul'
import PlanCarasoul from '../../components/PlanCarasoul'
import BookACall from '../../components/BookACall'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import PageLoader from '../../components/PageLoader'

const New = () => {
  const navigate = useNavigate()
  const [pageData, setPageData] = useState({})
  const [loading, setLoading] = useState(false)
  const getPageData = async () => {
    setLoading(true)
    try {
      let config = {
        method: 'get',

        url: `${process.env.REACT_APP_STRAPI_URL}/api/homes/1`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_TOKEN}`,
        },
        params: {
          populate: {
            image: {
              fields: ['name', 'url'],
            },
            features: {
              populate: ['image'],
            },
            bookACall: {
              populate: ['title'],
            },
          },
        },
      }

      const res = await axios.request(config)
      if (res?.data?.data?.id && res?.data?.data?.attributes?.publishedAt != null) {
        setPageData(res.data.data.attributes)
      }
    } catch (error) {
      console.log('error in fetch blogs', error)
    }
    setLoading(false)
    return true
  }
  useEffect(() => {
    getPageData()
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])
  const handleBookACall = () => {
    if (pageData?.bookACall?.targetBlank) {
      window.open(`${pageData?.bookACall?.path}`, '_blank')
    } else {
      navigate(`/${pageData?.bookACall?.path}`)
    }
  }
  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : (
        <div className=''>
          {/* Hero Section Start */}
          {pageData?.publishedAt != null && (
            <>
              <div className='flex justify-between items-center py-0 lg:py-5 w-full mx-auto flex-wrap md:py-0'>
                <div className='py-0 lg:py-[90px]'>
                  <div className='flex justify-between flex-col lg:flex-row flex-wrap'>
                    <div className='2xl:w-[50%] xl:w-[48%] w-full h-full'>
                      {pageData?.title && (
                        <h1 className='text-[#1D2939] font-semibold text-2xl lg:text-5xl text-start xl:text-start'>
                          {pageData?.title}
                        </h1>
                      )}
                      {pageData?.description && (
                        <p className='text-[#475467] font-normal text-sm lg:text-xl lg:mt-[15px] 2xl:mt-[25px] text-justify'>
                          {pageData?.description}
                        </p>
                      )}
                    </div>
                    {/*CARD Starts*/}
                    <div className='w-full 2xl:w-[45%] xl:w-[47%] xl:mt-0 mt-[40px]'>
                      <BookACall
                        title={pageData?.bookACall?.title}
                        description={pageData?.bookACall?.description}
                        btnText={pageData?.bookACall?.btnText}
                        handleBtnClick={handleBookACall}
                      />
                    </div>

                    {/*CARD Ends*/}
                  </div>
                </div>
              </div>
              {/* Hero Section End */}
              {/* LandScape Section Start */}
              <div className='flex justify-between items-center py-5 w-full mx-auto flex-wrap md:py-0 '>
                <div className='py-[40px]'>
                  <div className='flex flex-col'>
                    {pageData?.featuresHeading && (
                      <h1 className='font-semibold text-2xl lg:text-4xl'>
                        {pageData?.featuresHeading}
                      </h1>
                    )}
                    <div className='flex xl:flex-row flex-col-reverse xl:justify-between'>
                      <div className='flex flex-col w-auto xl:w-[50%] w-full'>
                        {pageData?.features?.length > 0 &&
                          pageData?.features?.map((item, index) => {
                            return (
                              <div className='flex items-start mt-[20px]' key={`new${index}`}>
                                <div className='mr-[10px] lg:mr-[30px]'>
                                  {(item?.image?.data?.attributes?.url ||
                                    item?.image?.data?.attributes?.alternativeText) && (
                                    <img
                                      src={item.image.data.attributes.url}
                                      alt={item.image.data.attributes.alternativeText ?? 'Image'}
                                      className='w-[90px] mt-[5px]'
                                    />
                                  )}
                                </div>
                                <div className=''>
                                  <h1 className='font-semibold text-lg lg:text-2xl'>
                                    {item.title}:
                                  </h1>
                                  <p className='font-normal text-sm lg:text-xl text-[#344054]'>
                                    {item.description}
                                  </p>
                                </div>
                              </div>
                            )
                          })}
                      </div>
                      {(pageData?.image?.data?.attributes?.url ||
                        pageData?.image?.data?.attributes?.alternativeText) && (
                        <div>
                          <img
                            src={pageData.image.data.attributes.url}
                            alt={pageData.image.data.attributes.alternativeText ?? 'Image'}
                            className='w-full lg:w-[60%] mx-auto xl:w-full mb-[30px] md:mb-0'
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* LandScape Section End */}
          <ClientsCarasoul />
          <PlanCarasoul heading={pageData?.planHeading} text={pageData?.planText} />
        </div>
      )}
    </div>
  )
}
export default New
