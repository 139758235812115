import React from 'react'

function OpenArrow() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='10' fill='none' viewBox='0 0 18 10'>
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M.848.896l8.148 8.098 8.15-8.098'
      ></path>
    </svg>
  )
}

export default OpenArrow
